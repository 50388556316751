import {
  GET_LANDLORDS,
  GET_LANDLORDS_SUCCESS,
  GET_LANDLORDS_ERROR,
  SET_LANDLORDS_FILTERS,
} from "../actions/landlords-actions";

const INITIAL_DATA = {
  pagination: {
    page: 1,
    totalPages: 1,
  },
  landlords: [],
  filters: [],
  status: "initial",
};

const landlordsReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_LANDLORDS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        status: "loading",
      };
    case GET_LANDLORDS_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        landlords: data || [],
        status: "success",
        pagination,
      };
    case GET_LANDLORDS_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case SET_LANDLORDS_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default landlordsReducer;
