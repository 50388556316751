import config from "../../config.js";

export const INIT_GOOGLE_MAPS = "INIT_GOOGLE_MAPS";
export const GOOGLE_MAPS_SUCCESS = "GOOGLE_MAPS_SUCCESS";
export const GOOGLE_MAPS_ERROR = "GOOGLE_MAPS_ERROR";

export const INIT_MARKER_CLUSTER = "INIT_MARKER_CLUSTER";
export const MARKER_CLUSTER_SUCCESS = "MARKER_CLUSTER_SUCCESS";
export const MARKER_CLUSTER_ERROR = "MARKER_CLUSTER_ERROR";

export const INIT_SELECTED_SPACE = "INIT_SELECTED_SPACE";
export const SET_SELECTED_SPACE = "SET_SELECTED_SPACE";
export const RESET_SELECTED_SPACE = "RESET_SELECTED_SPACE";

export const initGoogleMaps = () => ({
  type: INIT_GOOGLE_MAPS,
});

export const googleMapsSuccess = (data) => ({
  type: GOOGLE_MAPS_SUCCESS,
  payload: {
    data,
  },
});

export const googleMapsError = (data) => ({
  type: GOOGLE_MAPS_ERROR,
  payload: {
    data,
  },
});

export const initMarkerCluster = () => ({
  type: INIT_MARKER_CLUSTER,
});

export const markerClusterSuccess = (data) => ({
  type: MARKER_CLUSTER_SUCCESS,
  payload: {
    data,
  },
});

export const markerClusterError = (data) => ({
  type: MARKER_CLUSTER_ERROR,
  payload: {
    data,
  },
});

export const initSelectedSpace = () => ({
  type: INIT_SELECTED_SPACE,
});

export const setSelectedSpace = (data) => ({
  type: SET_SELECTED_SPACE,
  payload: {
    data,
  },
});

export const resetSelectedSpace = () => ({
  type: RESET_SELECTED_SPACE,
});

export const setSelectedSpaceThunk = (id) => {
  return function (dispatch, getState, { api }) {
    dispatch(initSelectedSpace());
    api.getSpace(id).then((data) => {
      if ( data?.data?.payload ) {
        dispatch(setSelectedSpace(data.data.payload));
      }
    });
  };
};

export const setSelectedAdSpaceThunk = (id) => {
  return function (dispatch, getState, { api }) {
    dispatch(initSelectedSpace());
    api.getAdSpaceById(id).then((data) => {
      dispatch(setSelectedSpace(data.data.payload));
    });
  };
};

export function initGoogleMapsThunk() {
  return function (dispatch) {
    dispatch(initGoogleMaps());
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAPS_API_KEY}`;
    script.onload = () => {
      dispatch(googleMapsSuccess(window.google));
    };
    script.onerror = (e) => {
      dispatch(googleMapsError(e));
    };
    document.body.appendChild(script);
  };
}

export function initMarkerClusterThunk() {
  return function (dispatch) {
    dispatch(initMarkerCluster());
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js`;
    script.onload = () => {
      dispatch(markerClusterSuccess(window.MarkerClusterer));
    };
    script.onerror = (e) => {
      dispatch(markerClusterError(e));
    };
    document.body.appendChild(script);
  };
}
