import {
  SHOW_TENANT_BOOKING_INVOICES,
  HIDE_TENANT_BOOKING_INVOICES
} from "../actions/tenant-booking-invoices-actions";

const INITIAL_STATE = {
  isOpen: false,
  bookingId: ''
};

const tenantBookingInvoicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_TENANT_BOOKING_INVOICES:
      return {
        ...action.payload,
        isOpen: true,
      }
    case HIDE_TENANT_BOOKING_INVOICES:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return {
        ...state,
      }
  }
}

export default tenantBookingInvoicesReducer;