// Types
import PopUpIcon from "./../icons/retail-shop-icon";
import ParkingIcon from "./../icons/parking-icon";
import ShoppingCenterIcon from "./../icons/shopping-icon";
import VendingIcon from "./../icons/vending-icon";
import GalleryIcon from "./../icons/gallery-icon";
import OutsideIcon from "./../icons/outside-icon";

// Subtypes
import Others from "./../icons/others-icon";
import Kiosk from "./../icons/kiosk-icon";
import LocalPopupIcon from "./../icons/local-popup-icon";
import FoodVansIcon from "./../icons/food-vans";
import CommunitySitesIcon from "./../icons/community-sites";

// Uses
import RetailIcon from "./../icons/retail-icon";
import BarIcon from "./../icons/bar-icon";
import EventoIcon from "./../icons/event-icon";

export default (key) => {
  switch (key) {
    // Types
    case "parking":
      return ParkingIcon;
    case "pie_calle":
      return PopUpIcon;
    case "mall":
    case "shopping_center":
      return ShoppingCenterIcon;
    // Subtypes
    case "others":
      return Others;
    case "kiosk-stand":
      return Kiosk;
    case "local-popup_store":
      return LocalPopupIcon;
    case "markets-food-vans":
      return FoodVansIcon;
    case "community-sites":
      return CommunitySitesIcon;
    case "vending":
      return VendingIcon;
    // Uses
    case "retail":
      return RetailIcon;
    case "bar":
      return BarIcon;
    case "event":
      return EventoIcon;
    case "commercial-gallery":
      return GalleryIcon;
    case "exterior-zone":
      return OutsideIcon;
    default:
      // TODO: prepare some generic icon
      return RetailIcon;
  }
};
