import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function MallRetail({ fill = "black", width = "70px", height = "70px", ...rest }) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 272.000000 254.000000"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <g transform="translate(-30.000000,295.000000) scale(0.122450,-0.127300)" fill={fill} stroke="none">
        <path
          d="M1483 2405 c-64 -19 -128 -60 -162 -104 -37 -46 -70 -117 -71 -148 0
-33 -67 -39 -186 -18 -136 24 -214 18 -297 -24 l-66 -34 -65 17 c-36 9 -74 16
-85 16 -11 0 -80 -50 -153 -112 -144 -122 -148 -125 -148 -149 0 -18 196 -249
212 -249 14 0 295 237 301 254 3 7 1 47 -4 87 -9 69 -8 75 12 91 26 22 111 48
156 48 18 0 81 -7 139 -16 59 -9 122 -14 141 -12 l33 4 0 -83 0 -83 -160 0
c-157 0 -161 0 -169 -22 -4 -13 -13 -111 -20 -218 -7 -107 -15 -216 -18 -242
l-6 -48 -222 0 c-198 0 -225 -2 -243 -17 -17 -16 -28 -79 -88 -524 -37 -278
-65 -513 -61 -522 6 -16 82 -17 1100 -17 837 0 1096 3 1105 12 18 18 17 1571
-1 1587 -8 6 -80 12 -188 13 l-174 3 -5 145 c-6 170 -19 209 -101 285 -83 78
-118 88 -304 91 -108 1 -174 -2 -202 -11z m33 -98 c-66 -81 -80 -126 -84 -280
l-4 -138 -57 3 -56 3 0 130 c0 125 2 132 30 184 35 63 77 98 148 122 29 10 53
18 55 19 2 0 -13 -19 -32 -43z m375 7 c40 -20 104 -98 117 -142 7 -22 12 -94
12 -161 l0 -122 -57 3 -58 3 -5 145 c-6 160 -21 209 -80 265 -17 16 -30 32
-30 36 0 9 65 -8 101 -27z m-192 1 c42 -21 106 -98 119 -143 7 -22 12 -94 12
-161 l0 -121 -162 2 -163 3 0 126 c0 120 1 128 30 185 22 43 43 68 79 92 56
37 49 36 85 17z m-1080 -291 c56 -16 59 -19 65 -53 14 -88 14 -89 -105 -190
-61 -51 -114 -90 -118 -85 -4 5 -33 39 -65 76 -32 37 -56 72 -54 77 2 9 208
190 216 191 1 0 29 -7 61 -16z m461 -236 c0 -18 -7 -116 -15 -218 -8 -102 -15
-191 -15 -197 0 -9 -18 -13 -56 -13 l-56 0 5 33 c3 17 11 121 18 230 l12 197
54 0 53 0 0 -32z m166 -30 c-9 -88 -26 -337 -26 -370 0 -27 -2 -28 -51 -28
l-51 0 5 43 c4 23 12 126 18 230 l12 187 49 0 50 0 -6 -62z m184 23 c0 -33 19
-71 35 -71 25 0 35 17 35 61 l0 49 260 0 260 0 0 -39 c0 -33 19 -71 35 -71 25
0 35 17 35 61 l0 49 155 0 155 0 -2 -732 -3 -733 -380 0 -380 0 -66 489 c-56
415 -69 491 -84 503 -13 8 -49 13 -107 13 -77 0 -88 2 -88 18 0 9 7 107 15
217 8 110 15 206 15 213 0 8 18 12 55 12 l55 0 0 -39z m-885 -533 c-3 -18 -30
-217 -60 -443 -30 -225 -58 -420 -60 -433 -5 -21 -11 -23 -53 -20 -40 3 -47 6
-45 23 8 70 115 855 119 878 5 25 9 27 55 27 l50 0 -6 -32z m175 27 c0 -16
-122 -902 -125 -912 -3 -8 -22 -13 -50 -13 -39 0 -45 3 -45 20 0 12 27 219 60
461 33 242 60 442 60 444 0 3 23 5 50 5 28 0 50 -2 50 -5z m720 -2 c0 -5 27
-206 60 -448 33 -242 60 -448 60 -457 0 -17 -28 -18 -445 -18 -349 0 -445 3
-445 13 0 6 27 210 60 452 33 242 60 446 60 453 0 9 72 12 325 12 179 0 325
-3 325 -7z"
        />
        <path
          d="M946 1184 c-19 -18 -22 -127 -6 -186 22 -79 91 -131 175 -131 120 0
185 82 185 231 0 87 -15 116 -48 95 -13 -8 -18 -30 -22 -94 -7 -120 -36 -159
-116 -159 -79 0 -114 54 -114 176 0 76 -20 102 -54 68z"
        />
      </g>
    </SvgIcon>
  );
}
