/* global GATSBY_THEME_I18N_LINGUI */
import * as React from "react"
import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import * as plurals from "make-plural/plurals";


const wrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale

  // we need to find the parent locale for the plurals
  const basicLocale = locale && locale.indexOf("-") > 0 ? locale.substring(0, locale.indexOf("-")) : "es";
  
  const { messages } = require(`${GATSBY_THEME_I18N_LINGUI}/${locale}/messages.js`)
  i18n.loadLocaleData(locale, { plurals: plurals[basicLocale] });
  i18n.load(locale, messages)
  i18n.activate(locale)

  return <I18nProvider i18n={i18n}>{element}</I18nProvider>
}

export { wrapPageElement }
