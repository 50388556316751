import { getPaginationData } from "../../utils";

export const GET_SPACES_OWNER = "GET_SPACES_OWNER";
export const GET_SPACES_OWNER_SUCCESS = "GET_SPACES_OWNER_SUCCESS";
export const GET_SPACES_OWNER_ERROR = "GET_SPACES_OWNER_ERROR";

export const GET_SPACE_OWNER = "GET_SPACE_OWNER";
export const GET_SPACE_OWNER_SUCCESS = "GET_SPACE_OWNER_SUCCESS";
export const GET_SPACE_OWNER_ERROR = "GET_SPACE_OWNER_ERROR";

export const SET_SPACES_FILTERS = "SET_SPACES_FILTERS";
export const SET_SPACES_SEARCH_WORDS = "SET_SPACES_SEARCH_WORDS";

export const SET_TIMELINE_DATE_RANGE_BLOCK = "SET_TIMELINE_DATE_RANGE_BLOCK";

export const RESET_ALL_SPACES = "RESET_ALL_SPACES";

export const getSpacesOwner = (page) => ({
  type: GET_SPACES_OWNER,
  payload: {
    page,
  },
});

export const getSpacesOwnerSuccess = (payload) => ({
  type: GET_SPACES_OWNER_SUCCESS,
  payload: {
    data: payload.data,
    pagination: payload.pagination,
  },
});

export const getSpacesOwnerError = (index, error) => ({
  type: GET_SPACES_OWNER_ERROR,
  payload: {
    index,
    error,
  },
});

export const getSpaceOwner = () => ({
  type: GET_SPACE_OWNER,
});

export const getSpaceOwnerSuccess = (data) => ({
  type: GET_SPACE_OWNER_SUCCESS,
  payload: data,
});

export const getSpaceOwnerError = (error) => ({
  type: GET_SPACE_OWNER_ERROR,
  payload: error,
});

export const setSpacesFilters = (filters) => ({
  type: SET_SPACES_FILTERS,
  payload: filters,
});

export const setSearchWords = (searchWords) => ({
  type: SET_SPACES_SEARCH_WORDS,
  payload: searchWords,
});

export const setTimelineDateRangeBlock = (block) => ({
  type: SET_TIMELINE_DATE_RANGE_BLOCK,
  payload: block,
});

export const resetAllSpaces = () => ({
  type: RESET_ALL_SPACES,
});

export function getSpacesOwnerThunk({ numberPerPage, page, filters, sort, search }) {
  return function (dispatch, _, { api }) {
    dispatch(getSpacesOwner(page));
    return api
      .getAdminSpaces(numberPerPage, page, { ...filters, search }, sort)
      .then((response) =>
        dispatch(
          getSpacesOwnerSuccess({
            ...response.data.payload,
            pagination: getPaginationData(response.data.payload),
          }),
        ),
      )
      .catch((error) => {
        dispatch(getSpacesOwnerError(page, error));
      });
  };
}

export function getSpaceOwnerThunk(id) {
  return function (dispatch, _, { api }) {
    dispatch(getSpaceOwner());
    return api
      .getSpace(id)
      .then((response) => dispatch(getSpaceOwnerSuccess(response.data.payload)))
      .catch((error) => {
        dispatch(getSpaceOwnerError(error));
      });
  };
}
