/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import axios from "axios";
import { formatDateUtc } from "../../utils/dateUtils";
import { CONSULT_STATUS, CONSULT_STATUS_REAL, CONSULT_STATUS_VIRTUAL } from "../../redux/reducers";
import config from "../../config";

const BASE_URL_API = `${config.API_URL}/api/v2/client`;

const getFilterFromState = (state) => {
  let mainFilter = "";
  if (CONSULT_STATUS_REAL.some((s) => s === state)) {
    mainFilter = `status=${state}`;
  } else if (CONSULT_STATUS_VIRTUAL.some((s) => s === state)) {
    // TODO: backend pide filtro de tipo in-progress, y las rutas internas
    // nuestras son inprogress
    mainFilter = state === "inprogress" ? `filter=in-progress` : `filter=${state}`;
  }

  return mainFilter;
};

export const logout = async () => {
  return axios.post(`${BASE_URL_API}/logout`, {});
};

export const refresh = async () => {
  return axios.post(`${BASE_URL_API}/refresh`, {});
};

export const getLoggedUser = async () => {
  return axios.get(`${BASE_URL_API}/me`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const patchLoggedUser = async (data) => {
  return axios.patch(`${BASE_URL_API}/me`, data);
};

export const getUrlPreSignedAvatar = async (id, filename, fileExt) => {
  return axios.post(`${BASE_URL_API}/me/avatar/presignedurl`, {
    file_name: filename,
    file_ext: fileExt,
  });
};

export const addUrlSignedAvatar = async (id, url) => {
  return axios.patch(`${BASE_URL_API}/me/avatar`, {
    url,
  });
};

export const getNotificationsUser = async () => axios.get(`${BASE_URL_API}/bookings/notifications`);

export const getClientConsultations = async ({
  status = CONSULT_STATUS.IN_PROGRESS,
  userId,
  pageSize = 5,
  page = 1,
  search = "",
}) => {
  // client understands all-bookings as all bookings & requests
  const mainFilter = status === "all-bookings" ? "" : getFilterFromState(status);
  return axios.get(
    `${BASE_URL_API}/bookings?${mainFilter}&page_size=${pageSize}&page=${page}&orderBy=updated_at&orderDir=desc&search=${search}`,
  );
};

export const postFirstContact = async ({ space, message, start, end, isLongTerm, paymentPlan, userId }) => {
  const bookingData = {
    brand_name: message.brandname,
    space_id: space.id,
    payment_plan: paymentPlan,
    project_name: message.projectname,
    project_details: message.description,
    space_activity_coefficient_id: message.spaceActivityCoefficient,
    user_id: userId,
    start,
  };

  if (isLongTerm) {
    bookingData.long_term = true;
  } else {
    bookingData.end = end;
  }

  return axios.post(`${BASE_URL_API}/bookings/create/${space.asset.id}`, bookingData);
};

export const postFirstContactWithoutSpace = async ({ assetId, adSpaces, message, start, end, long_term, userId }) =>
  axios.post(`${BASE_URL_API}/bookings/create/${assetId}`, {
    adspace_ids: adSpaces,
    brand_name: message.brandname,
    project_name: message.projectname,
    project_details: message.description,
    user_id: userId,
    start,
    end,
    long_term,
  });

export const getBookingTransactions = async ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/transactions`);

export const getBookingInvoices = async ({ bookingId }) => axios.get(`${BASE_URL_API}/bookings/${bookingId}/invoices`);

export const getUrlPreSignedInvoice = async (invoiceId, filename, fileExt) => {
  return axios.post(`${BASE_URL_API}/invoices/${invoiceId}/proof-of-payment/presignedurl`, {
    file_name: filename,
    file_ext: fileExt,
  });
};

export const addUrlSignedInvoice = async (invoiceId, url) => {
  return axios.patch(`${BASE_URL_API}/invoices/${invoiceId}/proof-of-payment`, {
    url,
  });
};

export const sendMessage = async (bookingId, content) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/message`, {
    type: "text",
    body: content,
  });

export const getBooking = async ({ bookingId }) => axios.get(`${BASE_URL_API}/bookings/${bookingId}`);

export const getBookingFeed = async ({ bookingId, wholeChain = true }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/feed?wholeChain=${wholeChain}`);

export const getBookingOverlap = async ({ bookingId }) => axios.get(`${BASE_URL_API}/bookings/${bookingId}/overlapped`);

export const getBookingExtensionChain = ({ bookingId }) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/extensionchain`);

export const getBookingUnavailableDateRanges = async (bookingId) =>
  axios.get(`${BASE_URL_API}/bookings/${bookingId}/unavailability/items`);

export const setBookingSeen = async ({ bookingId }) => axios.post(`${BASE_URL_API}/bookings/${bookingId}/seen`, {});

export const sendBookingRequest = async ({ bookingId, userId }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/request`, {
    user_id: userId,
  });

export const signBooking = async ({ bookingId, url }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/sign`, {
    url,
  });

export const cancelBooking = async ({ bookingId }) => axios.post(`${BASE_URL_API}/bookings/${bookingId}/cancel`, {});

export const wantRescindBooking = ({ bookingId }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/rescind/request`, {});

export const getBookingRate = (id) => axios.get(`${BASE_URL_API}/bookings/${id}/rating`);

export const getBookingDefaultPrice = async ({ bookingId }) => axios.get(`${BASE_URL_API}/bookings/${bookingId}/price`);

export const getBookingPriceByDateRange = async ({ id, start, end, long_term }) => {
  let stringFilters = "";

  if (end && !long_term) stringFilters += `&end=${formatDateUtc(end, "YYYY-MM-DD")}`;
  if (long_term && start) stringFilters += "&long_term=true";

  return axios.get(
    `${BASE_URL_API}/bookings/${id}/calculate/price?start=${formatDateUtc(start, "YYYY-MM-DD")}${stringFilters}`,
  );
};

export const changeDates = async ({ bookingId, userId, startDate, endDate }) =>
  axios.post(`${BASE_URL_API}/bookings/${bookingId}/dates`, {
    user_id: userId,
    start: startDate,
    end: endDate,
  });

export const getBookingContract = async (bookingId) => axios.get(`${BASE_URL_API}/bookings/${bookingId}/contract`);
