import {
  GET_INVOICING_REPORT,
  GET_INVOICING_REPORT_SUCCESS,
  GET_INVOICING_REPORT_ERROR,
  GET_BOOKING_INVOICING_REPORT,
  GET_BOOKING_INVOICING_REPORT_SUCCESS,
  GET_BOOKING_INVOICING_REPORT_ERROR,
  GET_INVOICING_REPORT_FILTERS,
  GET_INVOICING_REPORT_FILTERS_SUCCESS,
  GET_INVOICING_REPORT_FILTERS_ERROR,
  SET_INVOICING_REPORT_FILTERS,
  GET_BOOKING_INVOICING_REPORT_FILTERS,
  GET_BOOKING_INVOICING_REPORT_FILTERS_SUCCESS,
  GET_BOOKING_INVOICING_REPORT_FILTERS_ERROR,
  SET_BOOKING_INVOICING_REPORT_FILTERS,
} from "../actions/invoicing-report-actions";

const INITIAL_DATA = {
  invoices: {
    data: [],
    pagination: {
      page: 1,
      totalPages: 1,
    },
  },
  bookings: {
    data: [],
    pagination: {
      page: 1,
      totalPages: 1,
    },
  },
  filters: {},
  bookingFilters: {},
  filtersOptions: {
    assets: [],
    companies: [],
    spaces: [],
    status: [],
  },
  bookingFiltersOption: {
    assets: [],
    companies: [],
    spaces: [],
    status: [],
  },
  orderables: [],
  bookingOrderables: [],
  status: "initial",
  statusBooking: "initial",
  statusFilter: "initial",
  statusBookingFilter: "initial",
  hasNext: true,
  hasPrevious: false,
};

const invoicingReportReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_BOOKING_INVOICING_REPORT:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          pagination: {
            ...state.bookings.pagination,
            page: action.payload.page,
          },
        },
        statusBooking: "loading",
      };
    case GET_BOOKING_INVOICING_REPORT_SUCCESS:
      return {
        ...state,
        bookings: {
          data: action.payload?.data,
          pagination: {
            ...action?.payload?.pagination,
          },
        },
        statusBooking: "success",
      };
    case GET_BOOKING_INVOICING_REPORT_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case GET_INVOICING_REPORT:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          pagination: {
            ...state.invoices.pagination,
            page: action.payload.page,
          },
        },
        status: "loading",
      };
    case GET_INVOICING_REPORT_SUCCESS: {
      const { chart, invoices, topSpaces, balance } = action.payload;
      return {
        ...state,
        invoices,
        balance: balance || 0,
        chart: chart || [],
        topSpaces: topSpaces || [],
        status: "success",
      };
    }
    case GET_INVOICING_REPORT_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case GET_INVOICING_REPORT_FILTERS:
      return {
        ...state,
        statusFilters: "loading",
      };
    case GET_INVOICING_REPORT_FILTERS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        statusFilters: "success",
        orderables: data.orderables,
        bookingFilters: {
          ...state.bookingFilters,
          year: data.year,
        },
        filtersOptions: {
          assets: Object.values(data?.asset).map((a) => ({ id: a.asset_id, name: a.asset_name })),
          companies: Object.values(data?.company).map((c) => ({ id: c.company_id, name: c.company_name })),
          status: [
            { id: "all", name: "Seleccionar todo" },
            { id: "unconfirmed", name: "orders_unconfirmed" },
            { id: "confirmed", name: "orders_confirmed" },
            { id: "paid", name: "orders_paid" },
          ], // just for the sake of translations
          bookingCategories: Object.values(data?.bookingCategories).map((bc) => ({
            id: bc.booking_category_id,
            name: bc.booking_category_slug,
          })),
        },
      };
    }
    case GET_INVOICING_REPORT_FILTERS_ERROR:
      return {
        ...state,
        statusFilters: "error",
        error: action.payload.error,
      };
    case SET_INVOICING_REPORT_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case GET_BOOKING_INVOICING_REPORT_FILTERS:
      return {
        ...state,
        statusBookingFilter: "loading",
      };
    case GET_BOOKING_INVOICING_REPORT_FILTERS_SUCCESS:
      const { data } = action.payload;
      // Eliminamos el filtro "unpaid"
      const cstatus = data?.castatus?.filter((s) => s !== "unpaid").map((s) => ({ id: s, name: `orders_${s}` })) || [];

      return {
        ...state,
        statusBookingFilter: "success",
        bookingFiltersOption: {
          assets: Object.values(data?.assets).map((a) => ({ id: a.asset_id, name: a.asset_name })),
          companies: Object.values(data?.companies).map((c) => ({ id: c.company_id, name: c.company_name })),
          bookingCategories: Object.values(data?.categories).map((bc) => ({
            id: bc.booking_category_id,
            name: bc.booking_category_slug,
          })),
          status: [{ id: "all", name: "Seleccionar todo" }, ...cstatus],
        },
      };
    case GET_BOOKING_INVOICING_REPORT_FILTERS_ERROR:
      return {
        ...state,
        statusBookingFilter: "error",
        error: action.payload.error,
      };
    case SET_BOOKING_INVOICING_REPORT_FILTERS:
      return {
        ...state,
        bookingFilters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default invoicingReportReducer;
