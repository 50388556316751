// Action Types
export const GET_SPACES_LIST = "GET_SPACES_LIST";
export const GET_SPACES_LIST_SUCCESS = "GET_SPACES_LIST_SUCCESS";
export const GET_SPACES_LIST_ERROR = "GET_SPACES_LIST_ERROR";

export const GET_SPACES_NEXT_LIST = "GET_SPACES_NEXT_LIST";
export const GET_SPACES_NEXT_LIST_SUCCESS = "GET_SPACES_NEXT_LIST_SUCCESS";
export const GET_SPACES_NEXT_LIST_ERROR = "GET_SPACES_NEXT_LIST_ERROR";

export const ADD_SPACES_FILTER = "ADD_SPACES_FILTER";
export const ADD_SPACES_FILTER_SUCCESS = "ADD_SPACES_FILTER_SUCCESS";
export const ADD_SPACES_FILTER_ERROR = "ADD_SPACES_FILTER_ERROR";
export const ADD_SPACES_FILTER_RESET_STATUS = "ADD_SPACES_FILTER_RESET_STATUS";

export const SET_SPACE_HOVER = "SET_SPACE_HOVER";

export const getSpaces = (asset) => ({
  type: GET_SPACES_LIST,
  payload: asset,
});

export const getSpacesSuccess = (asset, data) => ({
  type: GET_SPACES_LIST_SUCCESS,
  payload: { asset, ...data },
});

export const getSpacesError = (error) => ({
  type: GET_SPACES_LIST_ERROR,
  payload: error,
});

export const getSpacesNextList = () => ({
  type: GET_SPACES_NEXT_LIST,
});

export const getSpacesNextListSuccess = (data) => ({
  type: GET_SPACES_NEXT_LIST_SUCCESS,
  payload: data,
});

export const getSpacesNextListError = (error) => ({
  type: GET_SPACES_NEXT_LIST_ERROR,
  payload: error,
});

export const addSpacesFilter = () => ({
  type: ADD_SPACES_FILTER,
});

export const addSpacesFilterSuccess = (filter) => ({
  type: ADD_SPACES_FILTER_SUCCESS,
  payload: filter,
});

export const addSpacesFilterError = (error) => ({
  type: ADD_SPACES_FILTER_ERROR,
  payload: error,
});

export const setSpaceHover = (id) => ({
  type: SET_SPACE_HOVER,
  payload: id,
});

export const addSpacesFilterThunk = (filter) => {
  return async function (dispatch, getState, { api }) {
    const state = getState();
    const asset = filter.asset || state.spaceList.asset;
    dispatch(addSpacesFilter());
    dispatch(addSpacesFilterSuccess(filter));
    if (!asset) {
      return;
    }
    dispatch(getSpacesThunk(asset, state.spaceList.perPage, 1));
  };
};

export const getSpacesThunk = (asset, numberPerPage, page) => {
  return async function (dispatch, getState, { api }) {
    dispatch(getSpaces(asset));
    const state = getState();
    const filters = { ...state.spaceList.filters };
    if (!asset.config?.show_space_price) {
      delete filters.priceStart;
      delete filters.priceEnd;
    }

    return api
      .getSpaces(asset.id, numberPerPage, page, filters)
      .then((data) => {
        dispatch(getSpacesSuccess(asset, data.data.payload));
      })
      .catch((error) => {
        dispatch(getSpacesError(error));
      });
  };
};

export const getSpacesNextListThunk = (asset, numberPerPage, page) => {
  return async function (dispatch, getState, { api }) {
    dispatch(getSpacesNextList());
    const state = getState();

    const filters = { ...state.spaceList.filters };
    if (!asset.config?.show_space_price) {
      delete filters.priceStart;
      delete filters.priceEnd;
    }

    return api
      .getSpaces(asset.id, numberPerPage, page, filters)
      .then((data) => {
        dispatch(getSpacesNextListSuccess(data.data.payload));
      })
      .catch((error) => {
        dispatch(getSpacesNextListError(error));
      });
  };
};
