import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { useSelector } from "react-redux";

import { getThemeById } from "./../theme";
import themeAdmin from "./../theme-admin";
import { CssBaseline } from "@material-ui/core";

export default (props) => {
  const currentAsset = useSelector((state) => state.misc.currentAsset);
  let id = 0;
  // When SSG works, this comes in the props
  if (props.assetId) {
    id = props.assetId
  }else {
    id = currentAsset ? currentAsset.id : 0
  }
  let theme = getThemeById(id);
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
};