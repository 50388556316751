export const GET_PERFORMANCE_DASHBOARD = "GET_PERFORMANCE_DASHBOARD";
export const GET_PERFORMANCE_DASHBOARD_SUCCESS = "GET_PERFORMANCE_DASHBOARD_SUCCESS";
export const GET_PERFORMANCE_DASHBOARD_ERROR = "GET_PERFORMANCE_DASHBOARD_ERROR";

export const getPerformanceDashboard = (filters) => ({
  type: GET_PERFORMANCE_DASHBOARD,
  payload: {
    filters
  }
})

export const getPerformanceDashboardError = (error) => ({
  type: GET_PERFORMANCE_DASHBOARD_ERROR,
  payload: {
    error
  }
})

export const getPerformanceDashboardSuccess = (data) => ({
  type: GET_PERFORMANCE_DASHBOARD_SUCCESS,
  payload: {
    data
  }
})

export function getPerformanceDashboardThunk(filters) {
  return function (dispatch, getState, { api }) {
    dispatch(getPerformanceDashboard(filters));
    return api
      .getPerformanceDashboard(filters)
      .then((res) => {
        if (res.hasOwnProperty("data")) {
          dispatch(getPerformanceDashboardSuccess(res.data.payload))
        } else {
          dispatch(getPerformanceDashboardError(res.response?.data?.message))
        }
      });
  };
}