import {
  GET_INVOICE_GENERATE,
  GET_INVOICE_GENERATE_SUCCESS,
  GET_INVOICE_GENERATE_ERROR,
  RESET_INVOICE_GENERATE,
} from "../actions/invoice-generate-actions";

const INVOICE_GENERATE_INITIAL_DATA = {
  status: "initial",
  data: null,
};

const invoiceGenerateReducer = (state = INVOICE_GENERATE_INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_INVOICE_GENERATE:
      return {
        ...state,
        status: "loading",
      };
    case GET_INVOICE_GENERATE_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.invoice,
          net_total: Number(action.payload.invoice?.net_total),
          tax_total: Number(action.payload.invoice?.tax_total),
          total: Number(action.payload.invoice?.total),
          attachments: [],
        },
        status: "success",
      };
    case GET_INVOICE_GENERATE_ERROR:
      return {
        ...state,
        status: "error",
      };
    case RESET_INVOICE_GENERATE:
      return {
        ...INVOICE_GENERATE_INITIAL_DATA,
      };
    default:
      return {
        ...state,
      };
  }
};

export default invoiceGenerateReducer;
