import {
  INIT_GOOGLE_MAPS,
  GOOGLE_MAPS_SUCCESS,
  GOOGLE_MAPS_ERROR,
  INIT_MARKER_CLUSTER,
  MARKER_CLUSTER_SUCCESS,
  MARKER_CLUSTER_ERROR,
  INIT_SELECTED_SPACE,
  SET_SELECTED_SPACE,
  RESET_SELECTED_SPACE,
} from "../actions/google-maps-actions";

const INITIAL_STATE = {
  maps: null,
  mapsStatus: "initial",
  space: {},
  spaceStatus: "initial",
  clusterMarker: null,
  clusterMarkerStatus: "initial",
};

const googleMapsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_GOOGLE_MAPS:
      return {
        ...state,
        mapsStatus: "loading",
      };
    case GOOGLE_MAPS_SUCCESS:
      return {
        ...state,
        mapsStatus: "success",
        ...action.payload.data,
      };
    case GOOGLE_MAPS_ERROR:
      return {
        ...state,
        mapsStatus: "error",
      };
    case INIT_MARKER_CLUSTER: {
      return {
        ...state,
        clusterMarkerStatus: "loading",
      };
    }
    case MARKER_CLUSTER_SUCCESS: {
      return {
        ...state,
        clusterMarkerStatus: "success",
        clusterMarker: action.payload.data,
      };
    }
    case MARKER_CLUSTER_ERROR: {
      return {
        ...state,
        clusterMarkerStatus: "error",
      };
    }
    case INIT_SELECTED_SPACE: {
      return {
        ...state,
        spaceStatus: "loading",
      };
    }
    case SET_SELECTED_SPACE: {
      return {
        ...state,
        space: action.payload.data,
        spaceStatus: "success",
      };
    }
    case RESET_SELECTED_SPACE: {
      return {
        ...state,
        spaceStatus: "initial",
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default googleMapsReducer;
