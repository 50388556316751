/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
import { GET_IMPORTS, GET_IMPORTS_SUCCESS, GET_IMPORTS_ERROR } from "../actions/imports-actions";

const INITIAL_DATA = {
  pagination: {
    page: 1,
    totalPages: 1,
  },
  imports: [],
  status: "initial",
};

const importsReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_IMPORTS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        status: "loading",
      };
    case GET_IMPORTS_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        imports: data || [],
        status: "success",
        pagination,
      };
    case GET_IMPORTS_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default importsReducer;
