import { CONSULT_STATUS } from "../reducers";
import { getPaginationData } from "../../utils";

export const GET_CONSULTS_OWNER = "GET_CONSULTS_OWNER";
export const GET_CONSULTS_OWNER_SUCCESS = "GET_CONSULTS_OWNER_SUCCESS";
export const GET_CONSULTS_OWNER_ERROR = "GET_CONSULTS_OWNER_ERROR";
export const SET_CONSULTS_SEARCH_WORDS = "SET_CONSULTS_SEARCH_WORDS";
export const SET_CONSULTS_OWNER_STATE = "SET_CONSULTS_OWNER_STATE";
export const SET_CONSULTS_FILTERS = "SET_CONSULTS_FILTERS";
export const GET_CONSULTS_FILTERS = "GET_CONSULTS_FILTERS";
export const GET_CONSULTS_FILTERS_SUCCESS = "GET_CONSULTS_FILTERS_SUCCESS";
export const GET_CONSULTS_FILTERS_ERROR = "GET_CONSULTS_FILTERS_ERROR";

export const getConsultsOwner = (page) => ({
  type: GET_CONSULTS_OWNER,
  payload: {
    page,
  },
});

export const getConsultsOwnerSuccess = ({ pagination, data, state }) => ({
  type: GET_CONSULTS_OWNER_SUCCESS,
  payload: {
    pagination,
    data,
    state,
  },
});

export const getConsultsOwnerError = (page, error) => ({
  type: GET_CONSULTS_OWNER_ERROR,
  payload: {
    page,
    error,
  },
});

export const setConsultsOwnerState = (state) => ({
  type: SET_CONSULTS_OWNER_STATE,
  payload: state,
});

export const setSearchWords = (words) => ({
  type: SET_CONSULTS_SEARCH_WORDS,
  payload: words,
});

export const setConsultsFilters = (filter) => ({
  type: SET_CONSULTS_FILTERS,
  payload: filter,
});

export const getConsultsFilters = () => ({
  type: GET_CONSULTS_FILTERS,
});

export const getConsultsFiltersSuccess = (data) => ({
  type: GET_CONSULTS_FILTERS_SUCCESS,
  payload: {
    data,
  },
});

export const getConsultsFiltersError = (error) => ({
  type: GET_CONSULTS_FILTERS_ERROR,
  payload: {
    error,
  },
});

export function getConsultsOwnerThunk({ state = CONSULT_STATUS.PENDING, search, filters = {}, page, sort = {} }) {
  return function (dispatch, getState, { api }) {
    dispatch(getConsultsOwner(page));
    const userId = localStorage.getItem("user_id");

    return api
      .adminGetAllConsultations({ state, userId, search, filters, page, sort })
      .then((response) => {
        dispatch(
          getConsultsOwnerSuccess({
            data: response.data.payload.data,
            state,
            pagination: getPaginationData(response.data.payload),
          }),
        );
      })
      .catch((error) => {
        dispatch(getConsultsOwnerError(page, error));
      });
  };
}

export function getConsultsFiltersThunk(userId) {
  return function (dispatch, getState, { api }) {
    dispatch(getConsultsFilters());

    return api
      .getBookingFiltersByUser(userId)
      .then((response) => {
        dispatch(getConsultsFiltersSuccess(response.data?.payload));
      })
      .catch((error) => {
        dispatch(getConsultsFiltersError(error));
      });
  };
}
