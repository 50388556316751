import { getPaginationData } from "../../utils";
import { add, dateDiff } from "../../utils/dateUtils";

export const GET_ADSPACES = "GET_ADSPACES";
export const GET_ADSPACES_SUCCESS = "GET_ADSPACES_SUCCESS";
export const GET_ADSPACES_ERROR = "GET_ADSPACES_ERROR";
export const UPDATE_ADSPACES_LIST = "UPDATE_ADSPACES_LIST";

export const GET_ADSPACE = "GET_ADSPACE";
export const GET_ADSPACE_SUCCESS = "GET_ADSPACE_SUCCESS";
export const GET_ADSPACE_ERROR = "GET_ADSPACE_ERROR";

export const GET_ADSPACES_CATEGORIES = "GET_ADSPACES_CATEGORIES";
export const GET_ADSPACES_CATEGORIES_SUCCESS = "GET_ADSPACES_CATEGORIES_SUCCESS";
export const GET_ADSPACES_CATEGORIES_ERROR = "GET_ADSPACES_CATEGORIES_ERROR";

export const SET_ADSPACES_FILTERS = "SET_ADSPACES_FILTERS";
export const SET_ADSPACES_SEARCH_WORDS = "SET_ADSPACES_SEARCH_WORDS";

export const GET_ADSPACE_TYPES = "GET_ADSPACE_TYPES";
export const GET_ADSPACE_TYPES_SUCCESS = "GET_ADSPACE_TYPES_SUCCESS";
export const GET_ADSPACE_TYPES_ERROR = "GET_ADSPACE_TYPES_ERROR";

export const GET_ADSPACE_UNAVAILABLE_DATES = "GET_ADSPACE_UNAVAILABLE_DATES";
export const GET_ADSPACE_UNAVAILABLE_DATES_SUCCESS = "GET_ADSPACE_UNAVAILABLE_DATES_SUCCESS";
export const GET_ADSPACE_UNAVAILABLE_DATES_ERROR = "GET_ADSPACE_UNAVAILABLE_DATES_ERROR";

export const UPDATE_ADSPACE_DATA = "UPDATE_ADSPACE_DATA";
export const RESET_ADSPACE = "RESET_ADSPACE";

export const SET_ADSPACES_CONSULTATION = "SET_ADSPACES_CONSULTATION";
export const RESET_ADSPACES_CONSULTATION = "RESET_ADSPACES_CONSULTATION";

export const SET_ADSPACES_CONSULTATION_CATEGORY = "SET_ADSPACES_CATEGORY";
export const RESET_ADSPACES_CONSULTATION_CATEGORY = "RESET_ADSPACES_CATEGORY";

export const GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES = "GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES";
export const GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_SUCCESS =
  "GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_SUCCESS";
export const GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_ERROR = "GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_ERROR";

export const SET_ADSPACES_CONSULTATION_DATES = "SET_ADSPACES_CONSULTATION_DATES";

export const RESET_ALL_ADSPACES = "RESET_ALL_ADSPACES";

export const getAdSpaces = (page) => ({
  type: GET_ADSPACES,
  payload: {
    page,
  },
});

export const getAdSpacesSuccess = (payload) => ({
  type: GET_ADSPACES_SUCCESS,
  payload: {
    data: payload.data,
    pagination: payload.pagination,
  },
});

export const getAdSpacesError = (error) => ({
  type: GET_ADSPACES_ERROR,
  payload: error,
});

export const getAdspacesCategories = () => ({
  type: GET_ADSPACES_CATEGORIES,
});

export const getAdspacesCategoriesSuccess = (data) => ({
  type: GET_ADSPACES_CATEGORIES_SUCCESS,
  payload: data,
});

export const getAdspacesCategoriesError = (error) => ({
  type: GET_ADSPACES_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const updateAdSpaceList = (data) => ({
  type: UPDATE_ADSPACES_LIST,
  payload: data,
});

export const getAdSpace = () => ({
  type: GET_ADSPACE,
});

export const getAdSpaceSuccess = (data) => ({
  type: GET_ADSPACE_SUCCESS,
  payload: data,
});

export const getAdSpaceError = (data) => ({
  type: GET_ADSPACE_ERROR,
  payload: data,
});

export const setAdSpacesFilters = (filters) => ({
  type: SET_ADSPACES_FILTERS,
  payload: filters,
});

export const getAdSpaceTypes = () => ({
  type: GET_ADSPACE_TYPES,
});

export const getAdSpaceTypesSuccess = (data) => ({
  type: GET_ADSPACE_TYPES_SUCCESS,
  payload: data,
});

export const getAdSpaceTypesError = (error) => ({
  type: GET_ADSPACE_TYPES_ERROR,
  payload: error,
});

export const getAdSpaceUnavailableDates = () => ({
  type: GET_ADSPACE_UNAVAILABLE_DATES,
});

export const getAdSpaceUnavailableDatesSuccess = (data) => ({
  type: GET_ADSPACE_UNAVAILABLE_DATES_SUCCESS,
  payload: data,
});

export const getAdSpaceUnavailableDatesError = (data) => ({
  type: GET_ADSPACE_UNAVAILABLE_DATES_ERROR,
  payload: data,
});

export const updateAdSpaceData = (data) => ({
  type: UPDATE_ADSPACE_DATA,
  payload: data,
});

export const resetAdSpace = () => ({
  type: RESET_ADSPACE,
});

export const setAdSpaceConsultation = (data) => ({
  type: SET_ADSPACES_CONSULTATION,
  payload: data,
});

export const resetAdSpaceConsultation = () => ({
  type: RESET_ADSPACES_CONSULTATION,
});

export const setAdSpaceConsultationCategory = (data) => ({
  type: SET_ADSPACES_CONSULTATION_CATEGORY,
  payload: data,
});

export const resetAdSpaceConsultationCategory = () => ({
  type: RESET_ADSPACES_CONSULTATION_CATEGORY,
});

export const getAdSpacesConsultationUnavailableDates = () => ({
  type: GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES,
});

export const getAdSpacesConsultationUnavailableDatesSuccess = (data) => ({
  type: GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_SUCCESS,
  payload: data,
});

export const getAdSpacesConsultationUnavailableDatesError = (error) => ({
  type: GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_ERROR,
  payload: error,
});

export const setAdSpacesConsultationDates = (dates) => ({
  type: SET_ADSPACES_CONSULTATION_DATES,
  payload: dates,
});

export const setSearchWords = (words) => ({
  type: SET_ADSPACES_SEARCH_WORDS,
  payload: words,
});

export const resetAllAdSpaces = () => ({
  type: RESET_ALL_ADSPACES,
});

export function getAdSpacesThunk({ asset, filters }) {
  return function (dispatch, getState, { api }) {
    dispatch(getAdSpaces());
    return api
      .getAdSpaces({ asset, filters })
      .then((res) => dispatch(getAdSpacesSuccess(res.data.payload)))
      .catch((err) => dispatch(getAdSpacesError(err)));
  };
}

export function getAdSpacesCategoriesThunk() {
  return function (dispatch, getState, { api }) {
    dispatch(getAdspacesCategories());
    return api
      .getAdSpacesCategories()
      .then((res) =>
        dispatch(
          getAdspacesCategoriesSuccess({
            ...res.data.payload,
          }),
        ),
      )
      .catch((err) => dispatch(getAdspacesCategoriesError(err)));
  };
}

export function getAdSpaceUnavailableDatesThunk(id) {
  return function (dispatch, getState, { api }) {
    dispatch(getAdSpaceUnavailableDates());
    return api
      .getAdSpaceUnavailableDateRanges(id)
      .then((res) => {
        const data = res.data.payload;

        const filteredRanges = data.filter((range) => {
          const { end } = range;
          return dateDiff(new Date(), end) >= 0;
        });

        const unavailableDates = filteredRanges.reduce((acc, curr) => {
          const { start, end } = curr;
          const diff = dateDiff(start, end);
          for (let i = 0; i <= diff; i++) {
            const date = add(start, i, "day").format("YYYY-MM-DD");
            acc[date] = curr;
          }
          return acc;
        }, {});

        dispatch(
          getAdSpaceUnavailableDatesSuccess({
            filteredRanges,
            unavailableDates,
          }),
        );
      })
      .catch((err) => dispatch(getAdSpaceUnavailableDatesError(err)));
  };
}

export function getAdSpaceConsultUnavailableDatesThunk(adSpaces) {
  return function (dispatch, getState, { api }) {
    dispatch(getAdSpacesConsultationUnavailableDates());
    Promise.all(
      adSpaces.map((ad) => {
        return api.getAdSpaceUnavailableDateRanges(ad.id);
      }),
    )
      .then((res) => {
        let adSpacesUnavailableDates = {};
        let adSpacesFilteredDates = [];
        res.forEach((r) => {
          const filteredRanges = r.data.payload;

          const unavailableDates = filteredRanges.reduce((acc, curr) => {
            const { start, end } = curr;
            const diff = dateDiff(start, end);
            for (let i = 0; i <= diff; i++) {
              const date = add(start, i, "day").format("YYYY-MM-DD");
              acc[date] = curr;
            }
            return acc;
          }, {});

          adSpacesUnavailableDates = {
            ...adSpacesUnavailableDates,
            ...unavailableDates,
          };

          adSpacesFilteredDates = [...adSpacesFilteredDates, ...filteredRanges];
        });

        dispatch(
          getAdSpacesConsultationUnavailableDatesSuccess({
            adSpacesUnavailableDates,
            adSpacesFilteredDates,
          }),
        );
      })
      .catch((err) => dispatch(getAdSpacesConsultationUnavailableDatesError(err)));
  };
}

export function getAdSpaceThunk({ adSpaceId }) {
  return function (dispatch, getState, { api }) {
    const user = getState().user;
    const getAdspaceByIdApi = user?.data?.isAdmin ? api.getAdSpaceByIdAdmin : api.getAdSpaceById;
    dispatch(getAdSpace());
    return getAdspaceByIdApi(adSpaceId)
      .then((res) => dispatch(getAdSpaceSuccess(res.data.payload)))
      .catch((err) => dispatch(getAdSpaceError(err)));
  };
}

export function getAdminAdSpacesThunk({ numberPerPage, page, filters, sort, search }) {
  return function (dispatch, getState, { api }) {
    dispatch(getAdSpaces(page));
    return api
      .getAdminAdSpaces({
        numberPerPage,
        page,
        filters: { ...filters, search },
        sort,
      })
      .then((response) =>
        dispatch(
          getAdSpacesSuccess({
            ...response.data.payload,
            pagination: getPaginationData(response.data.payload),
          }),
        ),
      )
      .catch((error) => {
        dispatch(getAdSpacesError(page, error));
      });
  };
}

export function getAdSpaceTypesThunk() {
  return function (dispatch, _, { api }) {
    dispatch(getAdSpaceTypes());
    return api
      .getAdSpacesTypes()
      .then((res) => dispatch(getAdSpaceTypesSuccess(res.data.payload)))
      .catch((err) => dispatch(getAdSpaceTypesError(err)));
  };
}
