import {
  GET_SPACES_OWNER,
  GET_SPACES_OWNER_SUCCESS,
  GET_SPACES_OWNER_ERROR,
  GET_SPACE_OWNER,
  GET_SPACE_OWNER_SUCCESS,
  GET_SPACE_OWNER_ERROR,
  SET_SPACES_FILTERS,
  SET_TIMELINE_DATE_RANGE_BLOCK,
  SET_SPACES_SEARCH_WORDS,
  RESET_ALL_SPACES,
} from "../actions/spaces-owner-actions";

const INITIAL_DATA = {
  pagination: {
    page: 1,
    totalPages: 1,
  },
  spaces: [],
  filters: [],
  searchWords: "",
  status: "initial",
  spaceStatus: "initial",
  hasNext: true,
  hasPrevious: false,
  timelineDateRangeBlock: "",
};

const spacesOwnerReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_SPACES_OWNER:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        status: "loading",
      };
    case GET_SPACES_OWNER_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        spaces: data || [],
        status: "success",
        pagination,
      };
    case GET_SPACES_OWNER_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case GET_SPACE_OWNER:
      return {
        ...state,
        spaceStatus: "loading",
      };
    case GET_SPACE_OWNER_SUCCESS:
      const updatedSpace = action.payload;
      const updatedSpaces = state.spaces;
      const index = updatedSpaces.findIndex((s) => s.id === updatedSpace.id);
      updatedSpaces[index] = updatedSpace;
      return {
        ...state,
        spaceStatus: "success",
        spaces: [...updatedSpaces],
      };
    case GET_SPACE_OWNER_ERROR:
      return {
        ...state,
        spaceStatus: "error",
      };
    case SET_SPACES_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case SET_TIMELINE_DATE_RANGE_BLOCK:
      return {
        ...state,
        timelineDateRangeBlock: action.payload,
      };
    case SET_SPACES_SEARCH_WORDS:
      return {
        ...state,
        searchWords: action.payload,
      };
    case RESET_ALL_SPACES:
      return { ...INITIAL_DATA };
    default:
      return {
        ...state,
      };
  }
};

export default spacesOwnerReducer;
