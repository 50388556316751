import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function EventsIcon({ fill = "#E5E5E5", width = "22px", height = "22px", ...rest }) {
  return (
    <SvgIcon width={width} height={height} viewBox="0 0 123 123" {...rest}>
      <g transform="translate(-18.5000,135.000000) scale(0.130000,-0.117500)" fill={fill}>
        <path
          d="M556 1111 c-39 -23 -57 -28 -88 -23 -51 6 -82 -11 -104 -60 -12 -26
-30 -46 -55 -58 -60 -31 -69 -45 -69 -108 0 -45 -6 -66 -25 -94 -31 -45 -31
-63 -3 -108 15 -23 24 -57 28 -100 5 -68 6 -69 85 -115 17 -10 13 -20 -43
-119 -33 -60 -58 -115 -55 -122 4 -10 23 -14 64 -14 l59 0 22 -45 c24 -49 45
-63 64 -43 7 7 37 56 68 108 34 60 60 93 68 91 7 -3 27 -5 43 -5 17 0 36 2 43
5 8 2 34 -31 68 -91 31 -52 61 -101 68 -108 19 -20 40 -6 64 43 l22 45 59 0
c41 0 60 4 64 14 3 7 -22 62 -55 122 -56 99 -60 109 -43 119 79 47 80 47 83
113 2 46 9 72 27 99 31 45 31 65 0 111 -19 28 -25 49 -25 94 0 63 -9 77 -70
108 -23 12 -42 31 -50 51 -23 54 -48 70 -105 68 -40 -2 -61 4 -96 24 -25 15
-50 27 -55 27 -5 0 -31 -13 -58 -29z m113 -68 c28 -19 42 -22 80 -17 l46 6 22
-46 c18 -35 33 -51 68 -68 45 -22 45 -22 45 -75 0 -41 6 -63 26 -93 l26 -39
-23 -33 c-16 -22 -26 -52 -29 -92 -5 -58 -6 -60 -45 -80 -25 -14 -48 -36 -63
-64 -23 -41 -25 -42 -75 -42 -39 0 -62 -6 -89 -25 -20 -14 -40 -25 -43 -25 -3
0 -23 11 -43 25 -27 19 -50 25 -89 25 -50 0 -52 1 -75 43 -15 27 -38 49 -63
63 -39 20 -40 22 -45 80 -3 40 -13 70 -29 92 l-23 33 26 39 c20 30 26 52 26
93 0 53 0 53 43 74 31 15 48 32 65 67 l23 46 53 -3 c44 -2 60 3 91 25 21 15
43 24 49 20 6 -3 26 -17 45 -29z m-216 -703 c26 0 47 -3 47 -7 -1 -5 -18 -39
-39 -77 l-39 -69 -16 32 c-14 27 -22 31 -57 31 l-40 0 33 59 c29 53 35 58 49
45 9 -8 36 -14 62 -14z m426 -88 c-32 -3 -42 -9 -55 -34 l-16 -31 -42 74 -42
74 52 3 c31 2 57 9 65 18 10 12 17 6 45 -43 l33 -58 -40 -3z"
        />
        <path
          d="M529 974 c-56 -17 -133 -82 -161 -137 -29 -57 -35 -151 -14 -214 51
-155 234 -230 382 -158 104 51 161 152 152 269 -7 78 -34 132 -94 185 -75 66
-168 85 -265 55z m152 -55 c119 -36 186 -188 130 -297 -24 -46 -77 -97 -116
-112 -42 -16 -126 -14 -166 3 -77 33 -129 113 -129 198 0 149 138 250 281 208z"
        />
        <path
          d="M471 811 c-12 -8 -13 -20 -1 -83 22 -128 11 -119 147 -116 l118 3 12
50 c7 28 16 71 19 96 5 39 3 47 -12 53 -11 4 -28 -1 -46 -14 -34 -25 -40 -25
-63 0 -10 11 -23 20 -30 20 -7 0 -20 -9 -30 -20 -10 -11 -22 -20 -27 -20 -6 0
-21 9 -35 20 -29 23 -32 23 -52 11z m206 -105 c18 5 21 3 16 -15 -5 -19 -13
-21 -78 -21 -65 0 -73 2 -78 21 -5 18 -2 20 17 15 16 -4 29 1 41 14 18 20 18
20 39 1 14 -13 29 -18 43 -15z"
        />
      </g>
    </SvgIcon>
  );
}
