// Action Types
export const SHOW_TENANT_BOOKING_INVOICES = "SHOW_TENANT_BOOKING_INVOICES";
export const HIDE_TENANT_BOOKING_INVOICES = "HIDE_TENANT_BOOKING_INVOICES";

export const showTenantBookingInvoices = (bookingId) => ({
  type: SHOW_TENANT_BOOKING_INVOICES,
  payload: {
    bookingId,
  },
});

export const hideTenantBookingInvoices = () => ({
  type: HIDE_TENANT_BOOKING_INVOICES,
});