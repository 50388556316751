import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CustomerAcquisition({ fill = "black", width = "70px", height = "70px", ...rest }) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 367.000000 318.000000"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <g transform="translate(-60.000000,400.000000) scale(0.140000,-0.135000)" fill={fill} stroke="none">
        <path
          d="M1632 3097 c-47 -17 -101 -64 -119 -103 l-17 -34 -88 0 c-104 0 -150
-16 -172 -59 -22 -42 -23 -338 -1 -380 30 -59 45 -61 463 -61 219 0 391 4 406
10 14 5 34 18 46 30 19 19 20 32 20 205 l0 185 -29 32 c-29 33 -30 33 -135 36
l-106 4 -14 31 c-38 89 -159 138 -254 104z m136 -130 c12 -12 25 -40 30 -62
11 -55 35 -65 160 -65 l102 0 0 -130 0 -130 -360 0 -360 0 0 129 0 129 121 4
c132 3 149 11 149 68 0 15 11 39 25 54 33 35 101 37 133 3z"
        />
        <path
          d="M814 2786 c-41 -19 -55 -33 -73 -68 l-21 -44 0 -1072 c0 -889 2
-1078 14 -1105 16 -39 61 -82 99 -96 19 -8 300 -11 871 -11 l843 0 44 23 c31
16 51 36 67 67 22 44 22 50 22 431 0 361 -1 387 -18 402 -21 19 -45 21 -73 7
-18 -10 -19 -25 -19 -389 0 -346 -2 -381 -18 -400 l-17 -21 -840 2 -839 3 -13
25 c-17 34 -19 2086 -2 2124 14 31 33 36 141 36 94 0 118 11 118 55 0 42 -34
55 -140 55 -79 0 -104 -4 -146 -24z"
        />
        <path
          d="M2317 2792 c-22 -24 -22 -66 -1 -83 11 -9 53 -15 121 -17 130 -5 133
-8 133 -137 0 -99 7 -115 51 -115 50 0 59 21 59 130 0 109 -8 141 -48 184 -43
45 -80 56 -195 56 -88 0 -106 -3 -120 -18z"
        />
        <path
          d="M2847 2490 c-14 -11 -42 -48 -62 -81 -49 -80 -44 -96 45 -156 39 -25
70 -48 70 -51 0 -4 -251 -373 -460 -677 -53 -77 -140 -204 -194 -283 -67 -99
-113 -154 -145 -177 -65 -49 -71 -46 -54 25 14 62 29 85 655 994 70 102 80
133 52 160 -23 24 -57 19 -86 -11 -13 -16 -178 -253 -366 -529 l-341 -501 -35
-148 c-20 -81 -36 -158 -36 -171 0 -34 43 -60 75 -46 33 15 248 167 267 189 9
10 168 241 353 513 186 272 365 534 398 582 75 108 73 117 -24 182 -39 26 -69
50 -66 53 3 2 36 -16 74 -41 74 -50 101 -56 126 -28 24 26 21 59 -7 83 -34 29
-196 139 -205 139 -5 0 -20 -9 -34 -20z"
        />
        <path
          d="M1080 2065 l0 -195 195 0 195 0 0 195 0 195 -195 0 -195 0 0 -195z
m270 0 l0 -75 -75 0 -75 0 0 75 0 75 75 0 75 0 0 -75z"
        />
        <path
          d="M1646 2208 c-21 -30 -20 -44 4 -68 19 -19 33 -20 324 -20 264 0 307
2 320 16 23 22 20 71 -3 84 -12 6 -134 10 -325 10 -304 0 -305 0 -320 -22z"
        />
        <path
          d="M1658 1999 c-22 -13 -24 -62 -2 -83 13 -14 50 -16 245 -16 216 0 230
1 249 20 25 25 26 52 2 73 -16 15 -48 17 -248 17 -137 0 -236 -5 -246 -11z"
        />
        <path
          d="M1080 1500 l0 -190 195 0 195 0 0 190 0 190 -195 0 -195 0 0 -190z
m270 0 l0 -80 -75 0 -75 0 0 80 0 80 75 0 75 0 0 -80z"
        />
        <path
          d="M1662 1544 c-32 -22 -27 -74 8 -91 32 -16 253 -17 283 -2 43 23 50
68 15 93 -33 23 -274 23 -306 0z"
        />
        <path
          d="M1080 935 l0 -195 195 0 195 0 0 195 0 195 -195 0 -195 0 0 -195z
m270 0 l0 -75 -75 0 -75 0 0 75 0 75 75 0 75 0 0 -75z"
        />
      </g>
    </SvgIcon>
  );
}
