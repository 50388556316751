// Action Types
export const SHOW_CARD = "SHOW_CARD";
export const HIDE_CARD = "HIDE_CARD";

export const showCard = ({ start, end, price, bookingId }) => ({
  type: SHOW_CARD,
  payload: {
    open: true,
    start,
    end,
    price,
    bookingId,
  },
});

export const hideCard = () => ({
  type: HIDE_CARD,
});