import { getPaginationData } from "../../utils";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";

export const getCompanies = (page) => ({
  type: GET_COMPANIES,
  payload: {
    page,
  },
});

export const getCompaniesSuccess = ({ pagination, data, search }) => ({
  type: GET_COMPANIES_SUCCESS,
  payload: {
    search,
    data,
    pagination,
  },
});

export const getCompaniesError = (index, error) => ({
  type: GET_COMPANIES_ERROR,
  payload: {
    index,
    error,
  },
});

export function getCompaniesThunk({ page, sort, search }) {
  return function (dispatch, _, { api }) {
    dispatch(getCompanies(page));
    return api
      .getCompanies({ page, sort, search })
      .then((response) => {
        dispatch(
          getCompaniesSuccess({
            search,
            data: response.data.payload.data,
            pagination: getPaginationData(response.data.payload),
          }),
        );
      })
      .catch((error) => {
        dispatch(getCompaniesError(page, error));
      });
  };
}
