import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_CITIES_AREAS,
  GET_CITIES_AREAS_SUCCESS,
  GET_CITIES_AREAS_ERROR,
  GET_SPACE_TYPES,
  GET_SPACE_TYPES_SUCCESS,
  GET_SPACE_TYPES_ERROR,
  GET_SPACE_FEATURES_SERVICES,
  GET_SPACE_FEATURES_SERVICES_SUCCESS,
  GET_SPACE_FEATURES_SERVICES_ERROR,
  SET_LOCALE,
  GET_CONTRACT_MODELS,
  GET_CONTRACT_MODELS_SUCCESS,
  GET_CONTRACT_MODELS_ERROR,
  SET_CURRENT_ASSET,
  RESET_CURRENT_ASSET,
  GET_RATING_CATEGORIES,
  GET_RATING_CATEGORIES_SUCCESS,
  GET_RATING_CATEGORIES_ERROR,
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_ERROR,
  GET_REGIONS,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERROR,
  GET_FEATURE_FLAGS,
  GET_FEATURE_FLAGS_SUCCESS,
  GET_FEATURE_FLAGS_ERROR,
  GET_SPOTS_BY_ASSET,
  GET_SPOTS_BY_ASSET_SUCCESS,
  SET_DASHBOARD_TITLE,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
  SET_DASHBOARD_TITLE_COMPONENT,
} from "../actions/misc-actions";
import { GET_SPACE_ERROR } from "../actions/space-actions";
import { transformAsset } from "./space-reducer";

// const MAX_ITEMS = 5;

const INITIAL_DATA = {
  status: "initial",
  statusSpaceFeaturesServices: "initial",
  dashboardTitle: "Dashboard",
  customComponentNextToTitle: null,
  countries: [],
  statusCountries: "initial",
  cities: [],
  assets: [],
  assetsPagination: {
    page: 1,
    totalPages: 1,
  },
  regions: [],
  adSpaces: [],
  spaceTypes: [],
  spaceSubTypes: [],
  spaceFeatures: [],
  spaceServices: [],
  error: "",
  locale: "es",
  isRemoteLocaleLoaded: false,
  contractModels: [],
  currentAsset: null,
  ratingCategories: [],
  featureFlags: {},
  featureFlagsStatus: "initial",
  spots: [],
  spotsStatus: "initial",
  ratingCategoriesStatus: "initial",
  assetsStatus: "initial",
  regionsStatus: "initial",
  adSpacesStatus: "initial",
  settings: {},
  settingsStatus: "initial",
};

const miscReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        statusCountries: "loading",
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        statusCountries: "success",
        countries: action.payload,
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        statusCountries: "error",
      };
    case GET_CITIES_AREAS:
      return {
        ...state,
        status: "loading",
      };
    case GET_CITIES_AREAS_SUCCESS: {
      const { data } = action.payload;
      const { cities, assets } = data
        .map((asset) => ({
          ...asset,
          latitude: parseFloat(asset.latitude),
          longitude: parseFloat(asset.longitude),
        }))
        .reduce(
          (acc, curr) => ({
            citiesObj: {
              ...acc.citiesObj,
              [curr.city.id]: curr.city,
            },
            cities: acc.citiesObj[curr.city.id] ? acc.cities : acc.cities.concat(curr.city),
            assets: acc.assets.concat(curr),
          }),
          { citiesObj: {}, cities: [], assets: [] },
        );
      return {
        ...state,
        cities,
        assets,
        status: "success",
      };
    }
    case GET_CITIES_AREAS_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case GET_SPACE_TYPES:
      return {
        ...state,
        statusSpaceTypes: "loading",
      };
    case GET_SPACE_TYPES_SUCCESS: {
      const { spaceTypes, spaceSubTypes } = action.payload;
      return {
        ...state,
        statusSpaceTypes: "success",
        spaceTypes: spaceTypes.payload.data,
        spaceSubTypes: spaceSubTypes.payload.data,
      };
    }
    case GET_SPACE_TYPES_ERROR:
      return {
        ...state,
        statusSpaceTypes: "error",
      };
    case GET_SPACE_FEATURES_SERVICES:
      return {
        ...state,
        statusSpaceFeaturesServices: "loading",
      };
    case GET_SPACE_FEATURES_SERVICES_SUCCESS: {
      const { spaceFeatures } = action.payload;

      return {
        ...state,
        statusSpaceFeaturesServices: "success",
        spaceFeatures,
      };
    }
    case GET_SPACE_FEATURES_SERVICES_ERROR:
      return {
        ...state,
        statusSpaceFeaturesServices: "error",
      };
    case SET_LOCALE: {
      return {
        ...state,
        locale: action.payload.locale,
        isRemoteLocaleLoaded: action.payload.isRemoteLocaleLoaded,
      };
    }
    case GET_CONTRACT_MODELS:
      return {
        ...state,
        statusContracts: "loading",
      };
    case GET_CONTRACT_MODELS_SUCCESS: {
      const contractModels = action.payload
        ? Object.keys(action.payload).map((cm) => ({
            id: cm,
            name: action.payload[cm],
          }))
        : [];

      return {
        ...state,
        statusContracts: "success",
        contractModels,
      };
    }
    case GET_CONTRACT_MODELS_ERROR:
      return {
        ...state,
        statusContracts: "error",
      };
    case SET_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: action.payload.asset,
      };
    case RESET_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: null,
      };
    case GET_RATING_CATEGORIES:
      return {
        ...state,
        ratingCategoriesStatus: "loading",
      };
    case GET_RATING_CATEGORIES_SUCCESS:
      return {
        ...state,
        ratingCategories: action.payload,
        ratingCategoriesStatus: "success",
      };
    case GET_RATING_CATEGORIES_ERROR:
      return {
        ...state,
        ratingCategoriesStatus: "error",
      };
    case GET_ASSETS:
      return {
        ...state,
        assetsPagination: {
          ...state.assetsPagination,
          page: action.payload.page,
        },
        assetsStatus: "loading",
      };
    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload.data.map((a) => transformAsset(a)),
        assetsPagination: {
          ...action.payload.pagination,
        },
        assetsStatus: "success",
      };
    case GET_ASSETS_ERROR:
      return {
        ...state,
        assetsStatus: "error",
      };
    case GET_REGIONS:
      return {
        ...state,
        regionsStatus: "loading",
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload.data,
        regionsStatus: "success",
      };
    case GET_REGIONS_ERROR:
      return {
        ...state,
        regionsStatus: "error",
      };
    case GET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlagsStatus: "loading",
      };
    case GET_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        featureFlags: { ...action.payload },
        featureFlagsStatus: "success",
      };
    case GET_FEATURE_FLAGS_ERROR:
      return {
        ...state,
        featureFlagsStatus: "error",
      };
    case GET_SPOTS_BY_ASSET:
      return {
        ...state,
        spotsStatus: "loading",
      };
    case GET_SPOTS_BY_ASSET_SUCCESS: {
      const { assetId, spots } = action.payload;
      const assignSpotOrder = (spot) => {
        let order;
        switch (spot.slug) {
          case "advertising":
            order = 3;
          case "retail":
            order = 2;
          case "promotion":
            order = 1;
          default:
            order = 1;
        }
        return {
          ...spot,
          order,
        };
      };
      const handleSpotsOrder = (a, b) => (a.order < b.order ? 1 : -1);
      return {
        ...state,
        spots: {
          ...state.spots,
          [assetId]: spots.map(assignSpotOrder).sort(handleSpotsOrder),
        },
        spotsStatus: "success",
      };
    }
    case SET_DASHBOARD_TITLE:
      return {
        ...state,
        dashboardTitle: action.payload,
      };
    case SET_DASHBOARD_TITLE_COMPONENT:
      return {
        ...state,
        customComponentNextToTitle: action.payload,
      };
    case GET_SPACE_ERROR:
      return {
        ...state,
        spotsStatus: "error",
      };
    case GET_SETTINGS:
      return {
        ...state,
        settingsStatus: "loading",
      };
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: { ...action.payload },
        settingsStatus: "success",
      };
    case GET_SETTINGS_ERROR:
      return {
        ...state,
        settingsStatus: "error",
      };
    default:
      return {
        ...state,
      };
  }
};

export default miscReducer;
