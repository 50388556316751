import numbro from "numbro";
import numbroLocale from "numbro/languages/en-AU";
export default () => {
  // https://numbrojs.com/languages.html
  const bacNumbro = {
    ...numbroLocale,
    currencyFormat: {
      mantissa: 2,
      thousandSeparated: true
    }
  };
  numbro.registerLanguage(bacNumbro, true);
}
