import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function AmbientMedia({ fill = "black", width = "90px", height = "90px", ...rest }) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 822.000000 513.000000"
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      <g transform="translate(-235.000000,725.000000) scale(0.1685000,-0.170420)" fill={fill} stroke="none">
        <path
          d="M4814 5026 c-83 -30 -122 -56 -181 -119 -176 -186 -173 -472 8 -648
l57 -57 -48 -101 -49 -101 -71 0 c-66 0 -125 18 -843 256 -425 141 -823 274
-886 295 -62 22 -124 39 -137 39 -61 0 -43 49 -589 -1585 -364 -1086 -514
-1547 -510 -1565 4 -14 16 -35 28 -46 15 -15 197 -80 542 -194 286 -95 528
-177 538 -182 15 -8 17 -28 17 -194 l0 -186 31 -29 31 -29 576 0 576 0 548
-180 c385 -127 554 -179 572 -174 45 11 58 36 148 297 l20 57 394 0 c428 0
438 1 462 55 17 37 17 3272 1 3309 -26 56 -25 56 -658 56 l-581 0 32 64 32 65
65 -6 c177 -15 365 96 447 262 124 254 -8 562 -277 646 -87 27 -215 25 -295
-5z m275 -191 c111 -56 175 -182 152 -297 -28 -131 -143 -228 -272 -228 -149
0 -279 130 -279 281 0 96 74 209 163 250 64 28 174 26 236 -6z m-1784 -650
l550 -183 -215 -2 c-118 0 -362 0 -543 0 -348 1 -371 -2 -397 -49 -7 -13 -10
-465 -10 -1375 0 -913 -3 -1356 -10 -1356 -11 0 -892 290 -899 296 -3 2 766
2322 789 2380 5 12 38 111 75 220 83 248 85 254 99 254 6 -1 259 -84 561 -185z
m1195 -379 c0 -2 -57 -119 -126 -261 -125 -254 -126 -257 -115 -296 8 -26 21
-44 41 -54 38 -20 59 -19 95 4 24 14 57 74 174 314 l145 297 578 0 578 0 0
-1520 0 -1520 -1495 0 -1495 0 0 430 c0 237 0 887 0 1445 0 558 0 1049 0 1090
l0 75 810 0 c446 0 810 -2 810 -4z m488 -3238 c-3 -7 -13 -37 -24 -67 -10 -29
-23 -55 -29 -57 -5 -2 -102 28 -215 65 l-205 69 239 1 c186 1 237 -2 234 -11z"
        />
        <path
          d="M3510 2910 c0 -5 4 -10 9 -10 16 0 31 -59 21 -84 -8 -23 -55 -66 -71
-66 -5 0 3 16 16 36 28 42 29 49 14 93 -9 24 -17 31 -38 31 -55 0 -90 -76 -61
-131 27 -50 2 -49 968 -49 627 0 909 4 931 11 63 22 80 102 32 150 l-29 29
-896 0 c-591 0 -896 -3 -896 -10z"
        />
        <path
          d="M3438 2234 c-58 -31 -67 -106 -17 -148 l31 -26 925 0 925 0 29 29
c46 47 34 117 -26 145 -48 23 -1826 23 -1867 0z"
        />
        <path
          d="M3416 1544 c-28 -29 -35 -79 -16 -115 27 -50 3 -48 968 -49 625 0
909 4 931 11 63 22 80 102 32 150 l-29 29 -930 0 -929 0 -27 -26z"
        />
        <path
          d="M8054 41 c-59 -36 -52 -41 60 -41 65 0 106 4 106 10 0 18 -64 50
-100 50 -19 0 -49 -9 -66 -19z"
        />
      </g>
    </SvgIcon>
  );
}
