import { t } from "@lingui/macro";
import { getPaginationData, sortAlphabetically } from "../../utils";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";
export const GET_CITIES_AREAS = "GET_CITIES_AREAS";
export const GET_CITIES_AREAS_SUCCESS = "GET_CITIES_AREAS_SUCCESS";
export const GET_CITIES_AREAS_ERROR = "GET_CITIES_AREAS_ERROR";
export const GET_SPACE_TYPES = "GET_SPACE_TYPES";
export const GET_SPACE_TYPES_SUCCESS = "GET_SPACE_TYPES_SUCCESS";
export const GET_SPACE_TYPES_ERROR = "GET_CITIES_AREAS_ERROR";
export const GET_SPACE_FEATURES_SERVICES = "GET_SPACE_FEATURES_SERVICES";
export const GET_SPACE_FEATURES_SERVICES_SUCCESS = "GET_SPACE_FEATURES_SERVICES_SUCCESS";
export const GET_SPACE_FEATURES_SERVICES_ERROR = "GET_SPACE_FEATURES_SERVICES_ERROR";
export const SET_LOCALE = "SET_LOCALE";
export const GET_CONTRACT_MODELS = "GET_CONTRACT_MODELS";
export const GET_CONTRACT_MODELS_SUCCESS = "GET_CONTRACT_MODELS_SUCCESS";
export const GET_CONTRACT_MODELS_ERROR = "GET_CONTRACT_MODELS_ERROR";
export const SET_CURRENT_ASSET = "SET_CURRENT_ASSET";
export const RESET_CURRENT_ASSET = "RESET_CURRENT_ASSET";
export const GET_RATING_CATEGORIES = "GET_RATING_CATEGORIES";
export const GET_RATING_CATEGORIES_SUCCESS = "GET_RATING_CATEGORIES_SUCCESS";
export const GET_RATING_CATEGORIES_ERROR = "GET_RATING_CATEGORIES_ERROR";
export const GET_ASSETS = "GET_ASSETS";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";
export const GET_ASSETS_ERROR = "GET_ASSETS_ERROR";
export const GET_FEATURE_FLAGS = "GET_FEATURE_FLAGS";
export const GET_FEATURE_FLAGS_SUCCESS = "GET_FEATURE_FLAGS_SUCCESS";
export const GET_FEATURE_FLAGS_ERROR = "GET_FEATURE_FLAGS_ERROR";
export const GET_REGIONS = "GET_REGIONS";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const GET_REGIONS_ERROR = "GET_REGIONS_ERROR";
export const GET_SPOTS_BY_ASSET = "GET_SPOTS_BY_ASSET";
export const GET_SPOTS_BY_ASSET_SUCCESS = "GET_SPOTS_BY_ASSET_SUCCESS";
export const GET_SPOTS_BY_ASSET_ERROR = "GET_SPOTS_BY_ASSET_ERROR";
export const SET_DASHBOARD_TITLE = "SET_DASHBOARD_TITLE";
export const SET_DASHBOARD_TITLE_COMPONENT = "SET_DASHBOARD_TITLE_COMPONENT";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";

export const getCountries = () => ({
  type: GET_COUNTRIES,
});

export const getCountriesSuccess = (data) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: data,
});

export const getCountriesError = (error) => ({
  type: GET_COUNTRIES_ERROR,
  payload: {
    error,
  },
});

export const getCitiesAreas = () => ({
  type: GET_CITIES_AREAS,
});

export const getCitiesAreasSuccess = (data) => ({
  type: GET_CITIES_AREAS_SUCCESS,
  payload: data,
});

export const getCitiesAreasError = (error) => ({
  type: GET_CITIES_AREAS_ERROR,
  payload: {
    error,
  },
});

export const getSpaceTypes = () => ({
  type: GET_SPACE_TYPES,
});

export const getSpaceTypesSuccess = (spaceTypes, spaceSubTypes) => ({
  type: GET_SPACE_TYPES_SUCCESS,
  payload: {
    spaceTypes,
    spaceSubTypes,
  },
});

export const getSpaceTypesError = (error) => ({
  type: GET_SPACE_TYPES_ERROR,
  payload: {
    error,
  },
});

export const getSpaceFeaturesServices = () => ({
  type: GET_SPACE_FEATURES_SERVICES,
});

export const getSpaceFeaturesServicesSuccess = (spaceFeatures) => ({
  type: GET_SPACE_FEATURES_SERVICES_SUCCESS,
  payload: {
    spaceFeatures,
  },
});

export const getSpaceFeaturesServicesError = (error) => ({
  type: GET_SPACE_FEATURES_SERVICES_ERROR,
  payload: {
    error,
  },
});

export const setLocale = (locale, isRemoteLocaleLoaded) => ({
  type: SET_LOCALE,
  payload: {
    locale,
    isRemoteLocaleLoaded,
  },
});

export const getContractModels = () => ({
  type: GET_CONTRACT_MODELS,
});

export const getContractModelsSuccess = (data) => ({
  type: GET_CONTRACT_MODELS_SUCCESS,
  payload: data,
});

export const getContractModelsError = (error) => ({
  type: GET_CONTRACT_MODELS_ERROR,
  payload: {
    error,
  },
});

export const setCurrentAsset = (asset) => ({
  type: SET_CURRENT_ASSET,
  payload: { asset },
});

export const resetCurrentAsset = () => ({
  type: RESET_CURRENT_ASSET,
});

export const getRatingCategories = () => ({
  type: GET_RATING_CATEGORIES,
});

export const getRatingCategoriesSuccess = (data) => ({
  type: GET_RATING_CATEGORIES_SUCCESS,
  payload: data,
});

export const getRatingCategoriesError = () => ({
  type: GET_RATING_CATEGORIES_ERROR,
});

export const getAssets = (page) => ({
  type: GET_ASSETS,
  payload: {
    page,
  },
});

export const getAssetsSuccess = (payload) => ({
  type: GET_ASSETS_SUCCESS,
  payload: {
    data: payload.data,
    pagination: payload.pagination,
  },
});

export const getAssetsError = (error) => ({
  type: GET_ASSETS_ERROR,
  payload: error,
});

export const getFeatureFlags = () => ({
  type: GET_FEATURE_FLAGS,
});

export const getFeatureFlagsSuccess = (data) => ({
  type: GET_FEATURE_FLAGS_SUCCESS,
  payload: data,
});

export const getFeatureFlagsError = (error) => ({
  type: GET_FEATURE_FLAGS_ERROR,
  payload: error,
});

export const getRegions = () => ({
  type: GET_REGIONS,
});

export const getRegionsSuccess = (regions) => ({
  type: GET_REGIONS_SUCCESS,
  payload: {
    data: regions,
  },
});

export const getRegionsError = (error) => ({
  type: GET_REGIONS_ERROR,
  payload: error,
});

export const getSpotsByAsset = () => ({
  type: GET_SPOTS_BY_ASSET,
});

export const getSpotsByAssetSuccess = (assetId, spots) => ({
  type: GET_SPOTS_BY_ASSET_SUCCESS,
  payload: {
    assetId,
    spots,
  },
});

export const getSpotsByAssetError = (error) => ({
  type: GET_SPOTS_BY_ASSET_ERROR,
  payload: error,
});

export const setDashboardTitle = (title) => ({
  type: SET_DASHBOARD_TITLE,
  payload: t({ id: title, message: title }),
});

export const setDashboardTitleComponent = (component) => ({
  type: SET_DASHBOARD_TITLE_COMPONENT,
  payload: component,
});

export const getSettings = () => ({
  type: GET_SETTINGS,
});

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: data,
});

export const getSettingsError = (error) => ({
  type: GET_SETTINGS_ERROR,
  payload: error,
});

// Thunks
export function getFeatureFlagsThunk() {
  return function (dispatch, getState, { api }) {
    dispatch(getFeatureFlags());
    api.getFeatureFlags().then((res) => {
      if (res.status === 200) {
        dispatch(getFeatureFlagsSuccess(res.data?.payload));
      } else {
        dispatch(getFeatureFlagsError(res.data?.message));
      }
    });
  };
}

export function setLocaleThunk(locale, isRemoteLocaleLoaded) {
  return function (dispatch, getState, { api }) {
    if (getState().user.isAuthenticated) {
      api.postUserLocale(getState().user.data.id, locale).then(() => {
        dispatch(setLocale(locale, isRemoteLocaleLoaded));
      });
    } else {
      dispatch(setLocale(locale, false));
    }
  };
}

export function getSpaceTypesThunk() {
  return function (dispatch, _, { api }) {
    dispatch(getSpaceTypes());
    return Promise.all([api.getSpaceTypes(), api.getSpaceSubTypes()])
      .then((res) => {
        dispatch(getSpaceTypesSuccess(res[0].data, res[1].data));
      })
      .catch((err) => dispatch(getSpaceTypesError(err)));
  };
}

export function getSpaceFeaturesThunk(asset = null) {
  return function (dispatch, _, { api }) {
    dispatch(getSpaceFeaturesServices());
    return api
      .getSpaceFeatures(asset)
      .then((res) => {
        const sortFeatures = sortAlphabetically(res.data.payload.data, "name");
        dispatch(getSpaceFeaturesServicesSuccess(sortFeatures));
      })
      .catch((err) => dispatch(getSpaceFeaturesServicesError(err)));
  };
}

export function getAssetsThunk({ regions, numberPerPage, page, sort } = {}) {
  return function (dispatch, getState, { api }) {
    dispatch(getAssets(page));
    return api
      .getAssets({ regions, numberPerPage, page, sort })
      .then((res) =>
        dispatch(
          getAssetsSuccess({
            ...res.data.payload,
            pagination: getPaginationData(res.data.payload),
          }),
        ),
      )
      .catch((err) => dispatch(getAssetsError(err)));
  };
}

export function getCitiesAreasThunk({ sort } = {}) {
  return function (dispatch, getState, { api }) {
    dispatch(getCitiesAreas());
    return api
      .getCitiesAreas({ sort })
      .then((res) => dispatch(getCitiesAreasSuccess(res.data.payload)))
      .catch((err) => dispatch(getCitiesAreasError(err)));
  };
}

export function getCountriesThunk() {
  return function (dispatch, _, { api }) {
    dispatch(getCountries());
    return api
      .getCountries()
      .then((res) => dispatch(getCountriesSuccess(res.data.payload.data)))
      .catch((err) => dispatch(getCountriesError(err)));
  };
}

export function getContractModelsThunk(asset) {
  return function (dispatch, _, { api }) {
    dispatch(getContractModels());
    return api
      .getContractModels(asset)
      .then((res) => dispatch(getContractModelsSuccess(res.data.payload)))
      .catch((err) => dispatch(getContractModelsError(err)));
  };
}

export function getRatingCategoriesThunk() {
  return function (dispatch, _, { api }) {
    dispatch(getRatingCategories());
    return api
      .getRatingCategories()
      .then((res) => {
        dispatch(getRatingCategoriesSuccess(res.data.payload));
      })
      .catch((err) => dispatch(getRatingCategoriesError(err)));
  };
}

export function getRegionsThunk() {
  return function (dispatch, _, { api }) {
    dispatch(getRegions());
    return api
      .getRegions()
      .then((res) => {
        dispatch(getRegionsSuccess(res.data.payload));
      })
      .catch((err) => getRegionsError(getRegionsError(err)));
  };
}

export function getSpotsByAssetThunk(assetId) {
  return function (dispatch, _, { api }) {
    dispatch(getSpotsByAsset());
    return api
      .getSpotsByAsset(assetId)
      .then((res) => {
        dispatch(getSpotsByAssetSuccess(assetId, res.data.payload));
      })
      .catch((err) => getSpotsByAssetError(getSpotsByAssetError(err)));
  };
}

export function getSettingsThunk() {
  return function (dispatch, _, { api }) {
    dispatch(getSettings());
    api.getSettings().then((res) => {
      if (res.status === 200) {
        dispatch(getSettingsSuccess(res.data?.payload));
      } else {
        dispatch(getSettingsError(res.data?.message));
      }
    });
  };
}
