import {
  SHOW_CARD,
  HIDE_CARD,
} from "../actions/space-card-actions";

const INITIAL_STATE = {
  title: "",
  city: "",
  area: "",
  start: "",
  end: "",
  price: 0,
  image: "",
  isOpen: false,
  bookingId: "",
  userId: "",
};

const SpaceCardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_CARD:
      return {
        ...action.payload,
        isOpen: true,
      }
    case HIDE_CARD:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return {
        ...state,
      }
  }
}

export default SpaceCardReducer;