import { GET_REGION, GET_REGION_SUCCESS, GET_REGION_ERROR, RESET_REGION } from "../actions/region-actions";

const REGION_INITIAL_DATA = {
  status: "initial",
  region: {},
};

const RegionReducer = (state = REGION_INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_REGION:
      return {
        ...state,
        status: "loading",
      };
    case GET_REGION_SUCCESS:
      return {
        ...state,
        region: {
          ...action.payload,
        },
        status: "success",
      };
    case GET_REGION_ERROR:
      return {
        ...state,
        status: "error",
      };
    case RESET_REGION:
      return {
        ...REGION_INITIAL_DATA,
      };
    default:
      return {
        ...state,
      };
  }
};

export default RegionReducer;
