import React from "react";
import BacThemeProvider from "../../providers/bac-theme-provider";
import IncompatibleBrowserComponnet from "../../components/incompatible-browser";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

function IncompatibleBrowser() {
  return (
    <BacThemeProvider>
      <I18nProvider i18n={i18n}>
        <IncompatibleBrowserComponnet />
      </I18nProvider>
    </BacThemeProvider>
  );
}

export default IncompatibleBrowser;
