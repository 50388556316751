import React, { useLayoutEffect, useState } from "react";
import { useLocalization } from "gatsby-theme-i18n";
import { setLanguageLocalStorage } from "../../utils";
import config from "../../config";

const UserLocale = ({ children }) => {
  const [hasChangedLanguage, setHasChangedLanguage] = useState(false);
  const { localizedPath } = useLocalization();

  const handleLoadUserLocale = () => {
    const userLocale = JSON.parse(localStorage.getItem("language"));
    if (userLocale) {
      const newLocale = userLocale?.language;
      const localePath = userLocale?.pathname;
      setLanguageLocalStorage(newLocale);
      const pathname = window.location.pathname;
      const search = window.location.search;

      if (newLocale === config.TENANT_DEFAULT_LOCALE) {
        setHasChangedLanguage(false);
      } else if (window.location.pathname.indexOf(newLocale) < 0 && localePath !== "/") {
        window.location.href = localizedPath({ locale: newLocale, path: `${pathname}${search}` });
        setHasChangedLanguage(true);
      } else {
        setHasChangedLanguage(false);
      }
    }
  };

  useLayoutEffect(() => {
    handleLoadUserLocale();
  }, []);

  return <>{!hasChangedLanguage && <>{children}</>}</>;
};

export default UserLocale;
