import * as plurals from "make-plural/plurals";
import { i18n } from "@lingui/core";
/** locales **/

export default async (locale) => {
  try {
    // we need to find the parent locale for the plurals
    const basicLocale = locale && locale.indexOf("-") > 0 ? locale.substr(0, locale.indexOf("-")) : "es";
    
    const { messages } = require(`/src/locales/${locale}/messages`);
    i18n.loadLocaleData(locale, { plurals: plurals[basicLocale] });
    i18n.load(locale, messages);
    i18n.activate(locale);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};
