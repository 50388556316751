// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-active-adspaces-js": () => import("./../../../src/pages/active-adspaces/[...].js" /* webpackChunkName: "component---src-pages-active-adspaces-js" */),
  "component---src-pages-active-spaces-asset-city-name-asset-name-js": () => import("./../../../src/pages/active-spaces/{Asset.city__name}-{Asset.name}.js" /* webpackChunkName: "component---src-pages-active-spaces-asset-city-name-asset-name-js" */),
  "component---src-pages-administrator-js": () => import("./../../../src/pages/administrator/[...].js" /* webpackChunkName: "component---src-pages-administrator-js" */),
  "component---src-pages-asset-spots-asset-city-name-asset-name-js": () => import("./../../../src/pages/asset-spots/{Asset.city__name}-{Asset.name}.js" /* webpackChunkName: "component---src-pages-asset-spots-asset-city-name-asset-name-js" */),
  "component---src-pages-consult-adspace-js": () => import("./../../../src/pages/consult-adspace/[...].js" /* webpackChunkName: "component---src-pages-consult-adspace-js" */),
  "component---src-pages-consult-space-js": () => import("./../../../src/pages/consult-space/[...].js" /* webpackChunkName: "component---src-pages-consult-space-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password/reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-region-assets-js": () => import("./../../../src/pages/region-assets/[...].js" /* webpackChunkName: "component---src-pages-region-assets-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-space-js": () => import("./../../../src/pages/space/[...].js" /* webpackChunkName: "component---src-pages-space-js" */),
  "component---src-pages-space-space-space-id-space-asset-name-space-title-js": () => import("./../../../src/pages/space/{Space.space_id}-{Space.asset__name}-{Space.title}.js" /* webpackChunkName: "component---src-pages-space-space-space-id-space-asset-name-space-title-js" */),
  "component---src-pages-tenant-js": () => import("./../../../src/pages/tenant/[...].js" /* webpackChunkName: "component---src-pages-tenant-js" */)
}

