export const GET_INVOICE_GENERATE = "GET_INVOICE_GENERATE";
export const GET_INVOICE_GENERATE_SUCCESS = "GET_INVOICE_GENERATE_SUCCESS";
export const GET_INVOICE_GENERATE_ERROR = "GET_INVOICE_GENERATE_ERROR";
export const RESET_INVOICE_GENERATE = "RESET_INVOICE_GENERATE";

export const getInvoiceGenerate = () => ({
  type: GET_INVOICE_GENERATE,
});

export const getInvoiceGenerateSuccess = (payload) => ({
  type: GET_INVOICE_GENERATE_SUCCESS,
  payload,
});

export const getInvoiceGenerateError = (error) => ({
  type: GET_INVOICE_GENERATE_ERROR,
  payload: error,
});

export const resetInvoiceGenerate = () => ({
  type: RESET_INVOICE_GENERATE,
});
