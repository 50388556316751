import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function GalleryIcon({ fill = "#E5E5E5", ...rest }) {
  return (
    <SvgIcon
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      style={{ fontSize: "90px" }}
      {...rest}
    >
      <path
        d="M160.095 144.431H153.652V50.1016C153.652 47.1951 151.895 44.8171 149.748 44.8171H116.948V34.7764L114.605 33.4553C105.038 27.9065 93.7143 25 81.8048 25C70.2857 25 59.1571 27.9065 49.5905 33.1911L47.0524 34.5122V45.3455H14.0571C11.9095 45.3455 10.1524 47.7236 10.1524 50.6301V144.431H3.90476C1.75714 144.431 0 146.809 0 149.715C0 152.622 1.75714 155 3.90476 155H160.095C162.243 155 164 152.622 164 149.715C164 146.809 162.243 144.431 160.095 144.431ZM17.9619 134.39H49.7857V144.167H17.9619V134.39ZM49.981 123.821H36.5095V73.8821H47.2476H49.981V123.821ZM28.7 123.821H18.1571V73.8821H28.7V123.821ZM57.7905 73.8821H106.21V144.167H97.6191V96.0772C97.6191 93.1707 95.8619 90.7927 93.7143 90.7927H70.2857C68.1381 90.7927 66.381 93.1707 66.381 96.0772V144.167H57.7905V73.8821ZM114.019 134.39H145.843V144.167H114.019V134.39ZM146.038 123.821H135.495V73.8821H146.038V123.821ZM127.49 123.821H114.019V73.8821H116.752H127.49V123.821ZM89.8095 144.167H74.1905V101.362H89.8095V144.167ZM146.038 55.3862V63.313H116.948V55.3862H146.038ZM54.8619 41.6463C62.8667 37.6829 72.0429 35.5691 81.6095 35.5691C91.3714 35.5691 100.743 37.6829 108.943 41.9106V63.313H54.8619V41.6463ZM47.0524 55.9146V63.313H17.9619V55.9146H47.0524Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
