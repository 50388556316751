// Action Types
export const SHOW_DATE_SELECT = "SHOW_DATE_SELECT";
export const HIDE_DATE_SELECT = "HIDE_DATE_SELECT";

export const showDateSelect = ({ spaceId, bookingId, startDate, endDate }) => ({
    type: SHOW_DATE_SELECT,
    payload: {
        isOpen: true,
        spaceId,
        bookingId,
        startDate,
        endDate,
    },
});

export const hideDateSelect = () => ({
    type: HIDE_DATE_SELECT,
});