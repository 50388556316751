import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function ParkingIcon({
  fill = "#E5E5E5",
  width = 26,
  height = 26,
  ...rest
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 82 168"
      fill={fill}
      {...rest}
    >
      <path
        d="M41.9004 50.0472C50.0041 50.0472 56.7571 43.7444 56.7571 35.8659C56.7571 27.9874 50.0041 21.6845 41.9004 21.6845H34.4721C34.4721 21.6845 34.4721 21.6845 34.247 21.6845C34.247 21.6845 34.247 21.6845 34.0219 21.6845C31.5458 21.6845 29.5199 23.7104 29.5199 26.1866V57.0254C29.5199 59.5015 31.5458 61.5274 34.0219 61.5274C36.498 61.5274 38.5239 59.5015 38.5239 57.0254V50.2723H41.9004V50.0472ZM47.7531 35.8659C47.7531 38.7922 45.0518 41.0432 41.9004 41.0432H38.749V30.4635H41.9004C45.0518 30.4635 47.7531 32.9396 47.7531 35.8659Z"
        fill={fill}
      />
      <path
        d="M52.4801 127.257C62.3846 127.257 70.4882 119.153 70.4882 109.249C70.4882 99.3443 62.3846 91.2407 52.4801 91.2407H45.7271V80.886C65.9862 78.635 81.5181 61.5274 81.5181 40.8181C81.5181 18.5331 63.285 0.299988 41 0.299988C18.715 0.299988 0.481873 18.5331 0.481873 40.8181C0.481873 61.7525 16.464 79.0852 36.7231 81.1111V91.4658H29.9701C20.0656 91.4658 11.962 99.5694 11.962 109.474C11.962 119.378 20.0656 127.482 29.9701 127.482H36.7231V158.996H16.6891C14.213 158.996 12.1871 161.022 12.1871 163.498C12.1871 165.974 14.213 168 16.6891 168H65.7611C68.2372 168 70.2631 165.974 70.2631 163.498C70.2631 161.022 68.2372 158.996 65.7611 158.996H45.7271V127.482H52.4801V127.257ZM9.4859 40.8181C9.4859 23.4854 23.6672 9.30401 41 9.30401C58.3327 9.30401 72.5141 23.4854 72.5141 40.8181C72.5141 58.1509 58.3327 72.3322 41 72.3322C23.6672 72.3322 9.4859 58.1509 9.4859 40.8181ZM20.966 109.249C20.966 104.297 25.0178 100.245 29.9701 100.245H52.4801C57.4323 100.245 61.4841 104.297 61.4841 109.249C61.4841 114.201 57.4323 118.253 52.4801 118.253H29.9701C25.0178 118.253 20.966 114.426 20.966 109.249Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
