import React from "react";

export default function EventIcon({ fill = "black", ...rest }) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M60.304 66.0928L40.5516 32H80.056L70.1736 49.0292L60.304 66.0928ZM45.4132 34.8L60.3016 60.51L67.7516 47.624L75.1904 34.8H45.4132Z" fill={fill}/>
      <path d="M61.6 62H58.8V94.4H61.6V62Z" fill={fill}/>
      <path d="M68 93.2001H52.8V96.0001H68V93.2001Z" fill={fill}/>
      <path d="M44 43.3693C38.4 43.3693 34.2848 39.0029 34.2848 33.6361C34.2848 28.2693 38.66 23.8853 44.0268 23.8853C49.3936 23.8853 53.7692 28.0001 53.7692 33.6001H50.9692C50.9692 29.6001 47.8592 26.6849 44.036 26.6849C40.2128 26.6849 37.0848 29.8041 37.0848 33.6269C37.0848 37.4497 40 40.5693 44 40.5693V43.3693Z" fill={fill}/>
      <path d="M44.0408 43.3749C42.898 43.3749 41.7524 43.1709 40.6492 42.7613C38.2116 41.8565 36.2728 40.0569 35.1888 37.6937C34.1052 35.3305 34.0064 32.6865 34.9112 30.2497C35.816 27.8121 37.6156 25.8733 39.9788 24.7893C42.3416 23.7057 44.986 23.6061 47.4228 24.5117C49.8604 25.4165 51.7992 27.2161 52.8832 29.5793L50.338 30.7465C48.7448 27.2717 44.6216 25.7397 41.146 27.3349C37.6708 28.9285 36.1404 33.0521 37.7344 36.5269C39.3276 40.0017 43.4508 41.5337 46.9264 39.9385L48.0936 42.4837C46.8 43.0761 45.4224 43.3749 44.0408 43.3749Z" fill={fill}/>
      <path d="M69.7552 34.0264L67.1584 32.9272C70.1716 25.458 70.8448 24 74.2052 24H85.6V26.8H74.2052C73.2564 26.8 73.1336 26.9 72.7488 27.4268C72.0828 28.3396 71.218 30.3996 69.7552 34.0264Z" fill={fill}/>

    </svg>
  );
}
