import {
  SHOW_DATE_SELECT,
  HIDE_DATE_SELECT,
} from "../actions/date-select-actions";

const INITIAL_STATE = {
  isOpen: false,
  spaceId: "",
  bookingId: "",
  startDate: new Date(),
  endDate: new Date(),
};

const DateSelectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_DATE_SELECT:
      return {
        ...action.payload,
        isOpen: true,
      }
    case HIDE_DATE_SELECT:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return {
        ...state,
      }
  }
}

export default DateSelectReducer;