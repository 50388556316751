import axios from "axios";
import { getFeatureFlagsThunk } from "./misc-actions";
// Action Types
export const POST_LOGIN = "POST_LOGIN";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";
export const POST_LOGOUT = "POST_LOGOUT";
export const URL_REDIRECT = "URL_REDIRECT";

// Action creator
export const postLogin = () => ({
  type: POST_LOGIN,
});

export const postLoginSuccess = (data) => ({
  type: POST_LOGIN_SUCCESS,
  payload: {
    data,
  },
});

export const postLoginError = (error) => ({
  type: POST_LOGIN_ERROR,
  payload: {
    error,
  },
});

export const postLogout = () => ({
  type: POST_LOGOUT,
  payload: {
    isAuthenticated: false,
    isOwner: false,
  },
});

export const urlRedirect = (data) => ({
  type: URL_REDIRECT,
  payload: data,
});

export function logoutThunk(redirect) {
  return function (dispatch, getState, { api }) {
    const { isAdmin } = getState().user.data;
    const logoutEndpoint = isAdmin ? api.adminLogout : api.logout;

    dispatch(postLogout());
    return logoutEndpoint()
      .then((e) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        redirect();
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function loginThunk(credentials) {
  return function (dispatch, getState, { api }) {
    dispatch(postLogin());
    return api
      .login(credentials)
      .then(({ data }) => {
        if (data && data.token) {
          localStorage.setItem("token", data?.token ? data?.token : "");
          localStorage.setItem("user_id", data.id);
          // TODO: move to utils
          axios.defaults.headers.common.Authorization = localStorage.getItem("token");

          setInterval(() => {
            if (localStorage.getItem("token")) {
              const { isAdmin } = getState().user.data;
              const refreshEndpoint = isAdmin ? api.adminRefresh : api.refresh;

              refreshEndpoint().then(({ data }) => {
                localStorage.setItem("token", data?.token ? data?.token : "");
              });
            }
          }, 1000 * 60 * 30);
          dispatch(postLoginSuccess(data));
          dispatch(getFeatureFlagsThunk());
        } else {
          dispatch(postLoginError("invalid"));
        }
      })
      .catch((error) => {
        dispatch(postLoginError(error));
      });
  };
}

export function loginSsoThunk(token) {
  localStorage.setItem("token", token || "");
  axios.defaults.headers.common.Authorization = localStorage.getItem("token");

  return function (dispatch, getState, { api }) {
    const { isAdmin } = getState().user.data;
    const getLoggedUserEndpoint = isAdmin ? api.adminGetLoggedUser : api.getLoggedUser;

    dispatch(postLogin());
    return getLoggedUserEndpoint()
      .then((response) => {
        if (response.data) {
          const data = response.data.payload;
          localStorage.setItem("user_id", data.id);
          dispatch(postLoginSuccess(data));
          dispatch(getFeatureFlagsThunk());
        } else {
          dispatch(postLoginError("invalid"));
        }
      })
      .catch((error) => {
        dispatch(postLoginError(error));
      });
  };
}
