import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RetailShopIcon({ fill = "black", ...rest }) {
  return (
    <SvgIcon width="120" height="120" viewBox="0 0 120 120" fill="none" {...rest}>
      <path d="M102.382 105.2H17.618L23.0872 40H96.9124L102.382 105.2ZM20.6624 102.4H99.3376L94.3376 42.8H25.662L20.6624 102.4Z" fill={fill}/>
      <path d="M79.2 50.8H76.4V33.9952C76.4 24.8956 68.9532 17.4924 59.8 17.4924C50.6468 17.4924 43.2 24.9192 43.2 34.0488V50.8H40.4V34.0488C40.4 23.3756 49.1028 14.6924 59.8 14.6924C70.4972 14.6924 79.2 23.3512 79.2 33.9952V50.8Z" fill={fill}/>
      <path d="M77.9 61.6572C74.7668 61.6572 72.2176 59.1076 72.2176 55.9744C72.2176 52.8412 74.7668 50.292 77.9 50.292C81.0332 50.292 83.5824 52.8412 83.5824 55.9744C83.5824 59.1076 81.0332 61.6572 77.9 61.6572ZM77.9 53.092C76.3104 53.092 75.0176 54.3848 75.0176 55.9744C75.0176 57.564 76.3104 58.8568 77.9 58.8568C79.4896 58.8568 80.7824 57.564 80.7824 55.9744C80.7824 54.3848 79.4896 53.092 77.9 53.092Z" fill={fill}/>
      <path d="M41.9 61.6572C38.7668 61.6572 36.2176 59.1076 36.2176 55.9744C36.2176 52.8412 38.7668 50.292 41.9 50.292C45.0332 50.292 47.5824 52.8412 47.5824 55.9744C47.5824 59.1076 45.0332 61.6572 41.9 61.6572ZM41.9 53.092C40.3104 53.092 39.0176 54.3848 39.0176 55.9744C39.0176 57.564 40.3104 58.8568 41.9 58.8568C43.4896 58.8568 44.7824 57.564 44.7824 55.9744C44.7824 54.3848 43.4896 53.092 41.9 53.092Z" fill={fill}/>
    </SvgIcon>
  );
}