import { useLocation } from "@reach/router";
import { t } from "@lingui/macro";
import slugify from "@sindresorhus/slugify";
import numbro from "numbro";
import userFormatter from "./user-formatter";
import getSpaceIcon from "./get-space-icon";
import downloadGeneratedContract from "./contract-generator";
import dynamicLoadLocale from "./dynamic-i18n";
import numbersToLetters from "./numbers-to-letters";
import * as spots from "./spots";
import config from "../config.js";
import extrapolateContractModel from "./extrapolate-contract-model";
import { dateToDayjs, isBefore, isSameOrBeforeDate } from "./dateUtils";
import { CONSULT_TRANSLATIONS } from "../redux/reducers";

export function slugifyFileNames(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s.-]/g, "")
    .replace(/\s+/g, "_")
    .trim();
}

export function userCanWrite(permissions) {
  if (permissions === "w" || permissions === "x") {
    return true;
  }
  return false;
}

export function userRoleAreaManager(role) {
  if (role === "Area Manager") {
    return true;
  }
  return false;
}

const BASIC_LOCALES = {
  en: t`Inglés`,
  es: t`Español`,
  fr: t`Francés`,
  pt: t`Portugués`,
  it: t`Italiano`,
  pl: t`Polaco`,
};

const SPACE_TYPE = {
  SPACE: t`Espacios`,
  ADSPACE: t`Espacios Publicitarios`,
};

export const getBaseLocale = (locale) => locale.substring(0, locale.indexOf("-"));

export const LOCALES = config.LOCALES_SUPPORTED_ARRAY.filter((locale) => locale.indexOf("-") > 0).reduce(
  (acc, curr) => {
    return {
      ...acc,
      [curr]: BASIC_LOCALES[getBaseLocale(curr)],
    };
  },
  {},
);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ROW_SIZE = 22;
const BIG_ROW_SIZE = 44;
const getBiggerRowsFromWindowSize = (containerHeight = window.innerHeight - 270) =>
  Math.round(containerHeight / BIG_ROW_SIZE);
const getRowsFromWindowSize = (containerHeight = window.innerHeight - 270) => Math.round(containerHeight / ROW_SIZE);
const limitStringChars = (s = "", limit = 20) => (s.length > limit ? `${s.substr(0, limit)}...` : s);

const leadingZero = (number) => (number > 9 ? number : `0${number}`);

const isMobile = () =>
  typeof navigator !== "undefined" &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const getFileNameFromUrl = (url) => decodeURI(url.substr(url.indexOf("_") + 1));

const getFileNameFromMessage = (msg) =>
  msg.match(/[!name](.*)(?=\[)/gi)?.length ? msg.match(/[!name](.*)(?=\[)/gi)[0].replace("!name]", "") : "";

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const areObjectsEqual = (first = {}, second = {}) => {
  const allKeys = Object.keys(first)
    .concat(Object.keys(second))
    .reduce((acc, curr) => ({
      ...acc,
      [curr]: true,
    }));

  return Object.keys(allKeys).filter((k) => first[k] === second[k]).length > 0;
};

/** Gets percentage of @fields populated in @data */
const getFieldsCompleteness = (fields = [], data = {}) => {
  if (!fields.length) {
    return 100;
  }
  const complete = fields.filter((f) => data[f] && data[f].length);

  return Math.ceil((complete.length / fields.length) * 100);
};

function browserVersion(browser) {
  const { userAgent } = navigator;

  let browserVersion = -1;

  browserVersion = userAgent.substring(userAgent.indexOf(browser));
  browserVersion = userAgent.substring(userAgent.indexOf(browser) + browser.length + 1);

  if (browserVersion.indexOf(" ") != -1) {
    browserVersion = browserVersion.substring(0, browserVersion.indexOf(" "));
  }
  if (browserVersion.indexOf(";") != -1) {
    browserVersion = browserVersion.substring(0, browserVersion.indexOf(";"));
  }

  return browserVersion;
}

function checkBrowserCompatibility() {
  if (typeof navigator !== "undefined") {
    // Internet Explorer 11+
    if (navigator.userAgent.indexOf("Trident") != -1) {
      return Number(browserVersion("Trident")) >= 7;
    }
    // Chrome 2013+
    if (navigator.userAgent.indexOf("Chrome") != -1) {
      return parseFloat(browserVersion("Chrome")) >= 30;
    }

    // Firefox 2013+
    if (navigator.userAgent.indexOf("Firefox") != -1) {
      return parseFloat(browserVersion("Firefox")) >= 26;
    }

    // Safari 2013+
    if (navigator.userAgent.indexOf("AppleWebKit") != -1) {
      return parseFloat(browserVersion("AppleWebKit")) >= 537;
    }
  }

  return true;
}

const downloadBlobFiles = (res, filename) => {
  const hiddenElement = document.createElement("a");
  hiddenElement.href = window.URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

const priceFromPaymentPlan = (paymentPlan, price, periodPrice) => {
  if (paymentPlan === "Total" && !isNaN(price)) {
    return numbro(parseFloat(price)).formatCurrency();
  }
  if (paymentPlan === "Monthly" && !isNaN(periodPrice)) {
    return `${numbro(parseFloat(periodPrice)).formatCurrency()}/${t`mes`}`;
  }
  if (paymentPlan === "Weekly" && !isNaN(periodPrice)) {
    return `${numbro(parseFloat(periodPrice)).formatCurrency()}/${t`semana`}`;
  }
  return "";
};

const convertToVirtualState = (state, start, end, confirmedAt) => {
  if (state === "cancelled") return t`Cancelada`;

  if (confirmedAt) {
    if (isBefore(start, Date()) && isSameOrBeforeDate(Date(), end)) {
      return t`Activa`;
    }
    if (isBefore(Date(), start)) {
      return t`Por comenzar`;
    }
    return t`Realizada`;
  }
  switch (state) {
    case "signed":
      return t`Firmada`;
    case "accepted":
      return t`Aceptada`;
    case "requested":
      return t`Solicitada`;
    case "pending":
      return t`Borrador`;
    default:
      return CONSULT_TRANSLATIONS[state] || "-";
  }
};

// TECHDEBT this is to enable when we have SSG for asset spaces list
const getAssetSlug = (asset) => (asset ? `${slugify(asset.city?.name)}-${slugify(asset?.name)}` : "");
const getSpaceSlug = (space) =>
  space && space.asset ? `${space.id}-${slugify(space?.asset?.name)}-${slugify(space?.title)}` : "";

const getPaginationData = (payload = {}) => ({
  totalPages: Math.ceil(payload.total / payload.per_page),
  page: payload.current_page,
});

const isDummyUser = ({ name = "", lastname = "" }) => name === "dumm" && lastname === "e";

const removeAWSSpecialCharacters = (f) => {
  return f.replace(/[^a-zA-Z0-9 \/|!|-|_|.|*|'|(|)]/g, "");
};

const debounce = (callback, wait = 500) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

const getLanguageType = (locale) => {
  if (locale) {
    return locale.split("-")[0];
  }
};

const setLanguageLocalStorage = (locale) => {
  const pathname = locale === config.TENANT_DEFAULT_LOCALE ? "/" : `/${locale}/`;
  localStorage.setItem(
    "language",
    JSON.stringify({ pathname, languageType: getLanguageType(locale), language: locale }),
  );
};

const getLanguageLocalStorage = () => {
  return JSON.parse(localStorage.getItem("language"));
};

const getFormattedBookingChain = (bookingChain = []) => {
  if (bookingChain.length === 1) {
    return bookingChain;
  }
  return filterAttributeIncludesAttribute(bookingChain, "tabLink", "tabName");
};

/**
 *
 * @param {*} bookingId
 * @param {*} bookingChain
 * @returns true if the booking is the only in the chain or is the parent of all
 * PD: There are places where `bookingChain.length && params.booking === bookingChain[0].tabLink` is used
 * It has a similar meaning, but in the future we might centralize that too.
 */
const isConsideredMainBooking = (bookingId, bookingChain) => {
  return bookingChain.length > 1 && bookingId === bookingChain[0].tabLink;
};

/**
 *
 * @param {*} list : array of objects
 * @param {*} paramToFind : attribute value to check it has @value
 * @param {*} value : attribute value to check it is included
 * @returns a new array containing the objects where attribute value @paramToFind
 * contains attribute value @value
 */
const filterAttributeIncludesAttribute = (list, paramToFind, value) => {
  return list.filter((l) => l?.[paramToFind].includes(l?.[value]));
};

const alphabetList = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const sanitizeMultiSelectOptions = (options) => {
  return options.filter((o) => typeof o.name === "string");
};
/**
 *
 * @param {*} list : array of objects
 * @param {*} key : it will be used to order array by key
 */
const sortAlphabetically = (list, key) => list.sort((a, b) => a[key].toString().localeCompare(b[key]));

const filterColumnsDisplayTableToString = (tableState) =>
  tableState?.columns
    ?.filter((c) => c.display === "true")
    .map((col) => col.name)
    .join(",") || "";

const sortArrayWithObj = (list, key) =>
  list.sort((a, b) =>
    isBefore(dateToDayjs(a[key]), dateToDayjs(b[key]))
      ? 1
      : isBefore(dateToDayjs(b[key]), dateToDayjs(a[key]))
      ? -1
      : 0,
  );

const adapterFilterInvoice = (list, isReserveView = false) => {
  const keys = Object.keys(list);
  const newAdapter = {};
  const keysFilter = isReserveView
    ? { assets: "assets", categories: "categories", companies: "companies" }
    : {
        assets: "asset",
        categories: "bookingCategories",
        companies: "company",
      };
  keys.forEach((key) => {
    switch (key) {
      case "assets":
        newAdapter[keysFilter[key]] = list[key].map((as) => ({ asset_id: as.id, asset_name: as.name }));
        break;
      case "categories":
        newAdapter[keysFilter[key]] = list[key].map((bk) => ({
          booking_category_id: bk?.id || null,
          booking_category_slug: bk?.slug || null,
        }));
        break;
      case "companies":
        newAdapter[keysFilter[key]] = list[key].map((com) => ({ company_id: com.id, company_name: com.name }));
        break;
      default:
        break;
    }
  });

  return newAdapter;
};

export {
  userFormatter,
  dynamicLoadLocale,
  getSpaceIcon,
  numbersToLetters,
  getBiggerRowsFromWindowSize,
  getRowsFromWindowSize,
  limitStringChars,
  useQuery,
  leadingZero,
  isMobile,
  getFileNameFromUrl,
  getFileNameFromMessage,
  downloadGeneratedContract,
  getLanguageType,
  clamp,
  areObjectsEqual,
  extrapolateContractModel,
  getFieldsCompleteness,
  checkBrowserCompatibility,
  getAssetSlug,
  downloadBlobFiles,
  priceFromPaymentPlan,
  convertToVirtualState,
  getSpaceSlug,
  getPaginationData,
  isDummyUser,
  removeAWSSpecialCharacters,
  spots,
  debounce,
  getLanguageLocalStorage,
  setLanguageLocalStorage,
  alphabetList,
  getFormattedBookingChain,
  isConsideredMainBooking,
  filterAttributeIncludesAttribute,
  sanitizeMultiSelectOptions,
  SPACE_TYPE,
  sortAlphabetically,
  filterColumnsDisplayTableToString,
  sortArrayWithObj,
  adapterFilterInvoice,
};
