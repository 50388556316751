import axios from "axios";
import config from "../config.js";
import { getRowsFromWindowSize } from "../utils";

const BASE_URL_API = `${config.API_URL}/api/v1`;

const handleFileDownload = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const getSpacesImportSample = async (exportFormat = "xls") => {
  return axios
    .get(`${BASE_URL_API}/export/spacessample?exportFormat=${exportFormat}`, {
      responseType: "blob",
    })
    .then((response) => {
      handleFileDownload(response.data, "spaces-sample.csv");
    });
};

export const getAdspacesImportSample = async (exportFormat = "xls") => {
  return axios
    .get(`${BASE_URL_API}/export/adspacesSample?exportFormat=${exportFormat}`, {
      responseType: "blob",
    })
    .then((response) => {
      handleFileDownload(response.data, "adspaces-sample.csv");
    });
};

export const getCompaniesImportSample = async (exportFormat = "xls") => {
  return axios
    .get(`${BASE_URL_API}/export/companiessample?exportFormat=${exportFormat}`, {
      responseType: "blob",
    })
    .then((response) => {
      handleFileDownload(response.data, "companies-sample.csv");
    });
};

export const importSpaces = async ({ file, assetId }) => {
  const formData = new FormData();
  formData.append("xls_file", file);
  formData.append("asset_id", assetId);

  return axios.post(`${BASE_URL_API}/import/spaces`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const importAdspaces = async ({ file, assetId }) => {
  const formData = new FormData();
  formData.append("xls_file", file);
  formData.append("asset_id", assetId);

  return axios.post(`${BASE_URL_API}/import/adspaces`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const importCompanies = async ({ file }) => {
  const formData = new FormData();
  formData.append("xls_file", file);

  return axios.post(`${BASE_URL_API}/import/companies`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getImportStatus = async (importId) => {
  return axios.get(`${BASE_URL_API}/import/status/${importId}`);
};

export const getImportsList = async ({ page, sort, size = getRowsFromWindowSize(), search }) => {
  let url = `${BASE_URL_API}/import/list?page_size=${size}&page=${page}`;
  if (sort) {
    url += `&orderBy=${sort.name}&orderDir=${sort.direction}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return axios.get(url);
};
