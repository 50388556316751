import { sortAlphabetically } from ".";

const ADMIN_TYPE = "App\\Models\\AdminProfile";

export default (user) => ({
  name: user.name,
  lastname: user.lastname,
  phone: user.phone,
  userType: user.type,
  isOwner: user.profile_type === ADMIN_TYPE,
  isAdmin: user.profile_type === ADMIN_TYPE,
  email: user.email,
  id: user.id,
  avatar: user?.avatar,
  locale: user.locale,
  managedAssets: sortAlphabetically(user.profile?.managed_assets || [], "name"),
  permissions: user.profile?.permissions,
  flags: user.flags,
  scopes: user.profile?.scopes,
  birthday: user?.birthday,
  role: user?.profile?.role,
});
