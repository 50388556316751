import {
  GET_PERFORMANCE_DASHBOARD,
  GET_PERFORMANCE_DASHBOARD_ERROR,
  GET_PERFORMANCE_DASHBOARD_SUCCESS,
} from "./../actions/performance-analyzer-actions";
import { getStartOf, getEndOf } from "../../utils/dateUtils";

const INITIAL_DATA = {
  filters: {
    start: getStartOf("year").toDate(),
    end: getEndOf("year").toDate(),
    assets: [],
  },
  dashboardStatus: "initial",
  dashboardError: "",
  // widgets
  data: {
    topBillingCompanies: [],
    monthlyIncome: [],
  },
};

const performanceAnalyzerReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_PERFORMANCE_DASHBOARD:
      return {
        ...state,
        filters: action.payload.filters,
        dashboardStatus: "loading",
      };
    case GET_PERFORMANCE_DASHBOARD_ERROR:
      return {
        ...state,
        dashboardStatus: "error",
        dashboardError: action.payload.error,
      };
    case GET_PERFORMANCE_DASHBOARD_SUCCESS: {
      return {
        ...state,
        dashboardStatus: "success",
        data: {
          ...action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};

export default performanceAnalyzerReducer;
