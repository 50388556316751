import { isMobile } from ".";
import FileType from "file-type/browser";

const getAttachmentFiles = async (attachments) => {
  const atts = {
    images: "",
    files: "",
    imagesHash: {},
  };

  for (let a of attachments) {
    //cannot fetch because cors issue.
    try {
      const ext = a.url.split(".").pop();
      if (ext === "png" || ext === "jpg" || ext === "jpeg") {
        atts.images += `<img src="${a.url}" onload="loadedImage('${a.url}')" /><div style="page-break-before: always;"> </div>`;
        atts.imagesHash[a.url] = true;
      } else {
        atts.files += `<a href="${a.url}">${a.url}</a>`;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return atts;
};

const fillContractPlaces = async (
  html,
  contractFields,
  attachments,
  contractModelAttachment
) => {
  let contract = html;

  const closeAction = isMobile() ? "" : "window.close()";
  const atts = await getAttachmentFiles(attachments);
  const imagesHash =
    "{" +
    Object.keys(atts.imagesHash)
      .map((k) => `"${k}": "${atts.imagesHash[k]}"`)
      .join(",") +
    "}";

  contract = contract.replaceAll("{{images}}", atts.images);
  contract = contract.replaceAll("{{files}}", atts.files);
  contract = contract.replaceAll("{{anexo_espacio}}", contractModelAttachment);

  const places = [...contract.matchAll(/{{(.*?)}}/gim)];

  places.map((p) => {
    if (contractFields.hasOwnProperty(p[1])) {
      contract = contract.replaceAll(p[0], contractFields[p[1]]);
    } else {
      contract = contract.replaceAll(
        p[0],
        "*..................................."
      );
    }
  });

  contract += `
    <script>
      var images = ${imagesHash}; 
      function loadedImage(url) {
        delete images[url];
        if(!Object.keys(images).length) {
          window.print();
          ${closeAction}
        }
      }
    </script>
    <img style="display: none;" src="/favicon.png" onload="loadedImage('')" />
  `;

  return contract;
};

const handleDownloadContract = async (
  templateUrl,
  data,
  attachments,
  contractModelAttachment = ""
) => {
  return fetch(`${templateUrl}?q=${new Date().getTime()}`)
    .then((res) => {
      return res.text();
    })
    .then(async (html) => {
      let printEl = document.getElementById("proforma-print");
      if (printEl) {
        printEl.remove();
      }
      printEl = document.createElement("iframe");
      printEl.id = "proforma-print";
      document.body.appendChild(printEl);

      const contractHtml = await fillContractPlaces(
        html,
        data,
        attachments,
        contractModelAttachment
      );

      printEl.srcdoc = contractHtml;
      printEl.style.display = "none";
    });
};

export default handleDownloadContract;
