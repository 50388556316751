import { GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_ERROR } from "../actions/companies-actions";

const INITIAL_DATA = {
  pagination: {
    page: 1,
    totalPages: 1,
  },
  companies: [],
  status: "initial",
};

const companiesReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        status: "loading",
      };
    case GET_COMPANIES_SUCCESS:
      const { search, data, pagination } = action.payload;
      return {
        ...state,
        search,
        companies: data || [],
        status: "success",
        pagination,
      };
    case GET_COMPANIES_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default companiesReducer;
