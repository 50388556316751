import React from "react";

import UserLocale from "./src/containers/user-locale";

import { CssBaseline } from "@material-ui/core";
import IncompatibleBrowser from "./src/containers/incompatible-browser";
import { Helmet } from "react-helmet";
import { checkBrowserCompatibility } from "./src/utils";

import config from "/src/config.js";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Techdebt: this is a workaround to avoid having <home /> from src/index.js
  // as base for all of the pages. check issue:
  /*if (typeof window !== "undefined" && window.location.pathname === "/") {
    console.log("element", element)
    return (
      <React.Fragment>
        <Helmet>
          <link href={config.FONT_API_URL} rel="stylesheet"></link>
        </Helmet>
        <CssBaseline />
        <Navbar />
        <Home />
      </React.Fragment>
    )
  }*/
  return (
    <React.Fragment>
      <UserLocale>
        <Helmet>
          <link href={config.FONT_API_URL} rel="stylesheet"></link>
          {config.INDEXABLE ? <meta name="robots" content="all" /> : <meta name="robots" content="noindex,nofollow" />}
        </Helmet>
        <CssBaseline />
        {checkBrowserCompatibility() ? element : <IncompatibleBrowser />}
      </UserLocale>
    </React.Fragment>
  );
};
