import axios from "axios";
import config from "../config.js";
import { formatDateUtc, getStartOf, getEndOf, isDateValid } from "../utils/dateUtils.js";

const BASE_URL_API = `${config.API_URL}/api/v1`;

export const getPerformanceDashboard = async (filters) => {
  const start = isDateValid(filters.start)
    ? formatDateUtc(filters.start, "YYYY-MM-DD")
    : formatDateUtc(getStartOf("year"), "YYYY-MM-DD");
  const end = isDateValid(filters.end)
    ? formatDateUtc(filters.end, "YYYY-MM-DD")
    : formatDateUtc(getEndOf("year"), "YYYY-MM-DD");

  const assets = filters.assets?.length > 0 ? filters.assets.map((a) => a.id) : [];
  const assetsByOwner = filters.clients?.length > 0 ? filters.clients.map((owner) => owner.assets).flat() : [];
  const assetsIds = [...new Set([...assets, ...assetsByOwner])].join(",");

  return axios.get(`${BASE_URL_API}/performance/dashboard?start=${start}&end=${end}&assets=${assetsIds}`);
};

export const getAnnualGoals = async (filters) => {
  const start = isDateValid(filters.start)
    ? formatDateUtc(filters.start, "YYYY-MM-DD")
    : formatDateUtc(getStartOf("year"), "YYYY-MM-DD");
  const end = isDateValid(filters.end)
    ? formatDateUtc(filters.end, "YYYY-MM-DD")
    : formatDateUtc(getEndOf("year"), "YYYY-MM-DD");

  const assets = filters.assets?.length > 0 ? filters.assets.map((a) => a.id) : [];
  const assetsByOwner = filters.clients?.length > 0 ? filters.clients.map((owner) => owner.assets).flat() : [];
  const assetsIds = [...new Set([...assets, ...assetsByOwner])].join(",");

  return axios.get(`${BASE_URL_API}/performance/goals?start=${start}&end=${end}&assets=${assetsIds}&option=true`);
};

export const getOwnersWithAssets = async () => {
  return axios.get(`${BASE_URL_API}/assets/owners`);
};
