import {
  GET_ADSPACES,
  GET_ADSPACES_SUCCESS,
  GET_ADSPACES_ERROR,
  UPDATE_ADSPACES_LIST,
  GET_ADSPACE,
  GET_ADSPACE_SUCCESS,
  GET_ADSPACE_ERROR,
  GET_ADSPACES_CATEGORIES,
  GET_ADSPACES_CATEGORIES_SUCCESS,
  GET_ADSPACES_CATEGORIES_ERROR,
  SET_ADSPACES_FILTERS,
  SET_ADSPACES_SEARCH_WORDS,
  GET_ADSPACE_TYPES,
  GET_ADSPACE_TYPES_SUCCESS,
  GET_ADSPACE_TYPES_ERROR,
  GET_ADSPACE_UNAVAILABLE_DATES,
  GET_ADSPACE_UNAVAILABLE_DATES_SUCCESS,
  GET_ADSPACE_UNAVAILABLE_DATES_ERROR,
  UPDATE_ADSPACE_DATA,
  RESET_ADSPACE,
  SET_ADSPACES_CONSULTATION,
  RESET_ADSPACES_CONSULTATION,
  SET_ADSPACES_CONSULTATION_CATEGORY,
  RESET_ADSPACES_CONSULTATION_CATEGORY,
  GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES,
  GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_SUCCESS,
  GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_ERROR,
  SET_ADSPACES_CONSULTATION_DATES,
  RESET_ALL_ADSPACES,
} from "../actions/adspaces-actions";

const ADSPACE_INITIAL_DATA = {
  id: -1,
  title: "",
  description: "",
  asset_id: -1,
  latitude: -1,
  longitude: 1,
  level: "",
  fortnight_price: 50,
  month_price: 100,
  adspace_type: "",
};

const INITIAL_DATA = {
  adSpaces: [],
  adSpace: ADSPACE_INITIAL_DATA,
  adSpaceUnavailableDates: {},
  adSpaceUnavailableDatesRange: [],
  adSpaceTypes: [],
  adSpacesConsultation: [],
  adSpacesConsultationDates: ["", ""],
  adSpacesConsultationUnavailableDates: [],
  adSpacesConsultationUnavailableDatesRange: [],
  filters: [],
  pagination: {
    page: 1,
    totalPages: 1,
  },
  searchWords: "",
  adSpacesStatus: "initial",
  adSpaceStatus: "initial",
  adSpaceTypesStatus: "initial",
  adSpaceUnavailableStatus: "initial",
  adSpaceConsultationUnavailableStatus: "initial",
};

const advertisingReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_ADSPACES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        adSpacesStatus: "loading",
      };
    case GET_ADSPACES_SUCCESS:
      return {
        ...state,
        adSpaces: action.payload.data,
        pagination: action.payload.pagination,
        adSpacesStatus: "success",
      };
    case GET_ADSPACES_ERROR:
      return {
        ...state,
        adSpacesStatus: "error",
      };
    case UPDATE_ADSPACES_LIST:
      const updatedSpace = action.payload;
      const updatedSpaces = state.adSpaces;
      const index = updatedSpaces?.findIndex((s) => s.id === updatedSpace.id);
      updatedSpaces[index] = updatedSpace;
      return {
        ...state,
        adSpaces: [...updatedSpaces],
      };
    case GET_ADSPACE:
      return {
        ...state,
        adSpaceStatus: "loading",
      };
    case GET_ADSPACE_SUCCESS:
      const attachments = action?.payload?.attachments?.map((file, index) => ({
        url: file.file_url,
        type: "image",
        role: `image-${file.attachable_id}-${index}`,
        id: file.id,
      }));
      return {
        ...state,
        adSpaceStatus: "success",
        adSpace: { ...action.payload, attachments },
      };
    case GET_ADSPACE_ERROR:
      return {
        ...state,
        adSpaceStatus: "error",
      };
    case GET_ADSPACES_CATEGORIES:
      return {
        ...state,
        adSpacesCategoriesStatus: "loading",
      };
    case GET_ADSPACES_CATEGORIES_SUCCESS:
      return {
        ...state,
        adSpacesCategories: Object.values(action.payload),
        adSpacesCategoriesStatus: "success",
      };
    case GET_ADSPACES_CATEGORIES_ERROR:
      return {
        ...state,
        adSpacesCategoriesStatus: "error",
      };
    case SET_ADSPACES_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case GET_ADSPACE_TYPES:
      return {
        ...state,
        adSpaceTypesStatus: "loading",
      };
    case GET_ADSPACE_TYPES_SUCCESS:
      return {
        ...state,
        adSpaceTypesStatus: "success",
        adSpaceTypes: action.payload,
      };
    case GET_ADSPACE_TYPES_ERROR:
      return {
        ...state,
        adSpaceTypesStatus: "error",
      };
    case GET_ADSPACE_UNAVAILABLE_DATES:
      return {
        ...state,
        adSpaceUnavailableStatus: "loading",
      };
    case GET_ADSPACE_UNAVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        adSpaceUnavailableStatus: "success",
        adSpaceUnavailableDates: action.payload.unavailableDates,
        adSpaceUnavailableDatesRange: action.payload.filteredRanges,
      };
    case GET_ADSPACE_UNAVAILABLE_DATES_ERROR:
      return {
        ...state,
        adSpaceUnavailableStatus: "error",
      };
    case RESET_ADSPACE:
      return {
        ...state,
        adSpace: ADSPACE_INITIAL_DATA,
        pagination: {
          page: 1,
          totalPages: 1,
        },
      };
    case RESET_ALL_ADSPACES:
      return { ...INITIAL_DATA };
    case UPDATE_ADSPACE_DATA:
      return {
        ...state,
        adSpace: {
          ...state.adSpace,
          ...action.payload,
        },
      };
    case SET_ADSPACES_CONSULTATION:
      return {
        ...state,
        adSpacesConsultation: action.payload,
      };
    case RESET_ADSPACES_CONSULTATION:
      return {
        ...state,
        adSpacesConsultation: [],
        adSpacesConsultationDates: ["", ""],
        adSpacesConsultationUnavailableDates: [],
        adSpacesConsultationUnavailableDatesRange: [],
      };
    case SET_ADSPACES_CONSULTATION_CATEGORY:
      return {
        ...state,
        adSpacesConsultationCategory: action.payload,
      };
    case RESET_ADSPACES_CONSULTATION_CATEGORY:
      return {
        ...state,
        adSpacesConsultationCategory: "",
      };
    case GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES:
      return {
        ...state,
        adSpaceConsultationUnavailableStatus: "loading",
      };
    case GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_SUCCESS:
      return {
        ...state,
        adSpaceConsultationUnavailableStatus: "success",
        adSpacesConsultationUnavailableDates: action.payload.adSpacesUnavailableDates,
        adSpacesConsultationUnavailableDatesRange: action.payload.adSpacesFilteredDates,
      };
    case GET_ADSPACES_CONSULTATION_UNAVAILABLE_DATES_ERROR:
      return {
        ...state,
        adSpaceConsultationUnavailableStatus: "error",
      };
    case SET_ADSPACES_CONSULTATION_DATES:
      return {
        ...state,
        adSpacesConsultationDates: action.payload,
      };
    case SET_ADSPACES_SEARCH_WORDS:
      return {
        ...state,
        searchWords: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default advertisingReducer;
