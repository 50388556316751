import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import consultationReducer from "./consultation-reducer";
import firstContactReducer from "./first-contact-reducer";
import consultsReducer from "./consults-reducer";
import spaceReducer from "./space-reducer";
import spacesOwnerReducer from "./spaces-owner-reducer";
import spaceCardReducer from "./space-card-reducer";
import dateSelectReducer from "./date-select-reducer";
import consultsOwnerReducer from "./consults-owner-reducer";
import specialOfferReducer from "./special-offer-reducer";
import miscReducer from "./misc-reducer";
import assetReducer from "./asset-reducer";
import regionReducer from "./region-reducer";
import adspacesReducer from "./adspaces-reducer";
import spaceListReducer from "./space-list-reducer";
import companiesReducer from "./companies-reducer";
import importsReducer from "./imports-reducer";
import googleMapsReducer from "./google-maps-reducer";
import landlordsReducer from "./landlords-reducer";
import invoicingReportReducer from "./invoicing-report-reducer";
import tenantBookingInvoicesReducer from "./tenant-booking-invoices-reducer";
import performanceAnalyzerReducer from "./performance-analyzer-reducer";
import invoiceGenerateReducer from "./invoice-generate-actions";
import usersReducer from "./users-reducer";
import pendingTransactionsReducer from "./pending-transactions-reducer";

export const HEIGHT_NAVBAR = 60;

export const MIN_REQUIRED_DAYS_MONTHLY_PAYMENT = 60;
export const MIN_REQUIRED_DAYS_WEEKLY_PAYMENT = 7;

export const MAX_MB_ATTACHMENTS = 30;
export const MAX_MB_IMAGES = 2;

export const PAYMENT_PLANS = Object.freeze({
  TOTAL: "Total",
  MONTHLY: "Monthly",
});

export const CONSULT_STATUS = Object.freeze({
  ACTIVE: "active",
  ALL_BOOKINGS: "all-bookings",
  ALL_REQUESTS: "all-requests",
  IN_PROGRESS: "inprogress", // GLOBAL STATE
  CONFIRMED: "confirmed", // GLOBAL STATE
  PENDING: "pending",
  REQUESTED: "requested",
  ACCEPTED: "accepted",
  SIGNED: "signed",
  UNPAID_N_CANCELLED: "unpaid-n-cancelled",
  TO_BE_STARTED: "to-be-started",
  PAID_N_CANCELLED: "paid-n-cancelled",
  CONFIRMED_N_CANCELLED: "confirmed-n-cancelled",
  PAID: "paid",
  DONE: "done",
  CANCELED: "cancelled",
  REJECTED: "rejected",
});

export const CONSULT_STATUS_REAL = [
  CONSULT_STATUS.PENDING,
  CONSULT_STATUS.REQUESTED,
  CONSULT_STATUS.ACCEPTED,
  CONSULT_STATUS.SIGNED,
  CONSULT_STATUS.PAID,
  CONSULT_STATUS.DONE,
  CONSULT_STATUS.CANCELED,
];

export const CONSULT_STATUS_VIRTUAL = [
  CONSULT_STATUS.ACTIVE,
  CONSULT_STATUS.ALL_BOOKINGS,
  CONSULT_STATUS.ALL_REQUESTS,
  CONSULT_STATUS.IN_PROGRESS,
  CONSULT_STATUS.CONFIRMED,
  CONSULT_STATUS.UNPAID_N_CANCELLED,
  CONSULT_STATUS.PAID_N_CANCELLED,
  CONSULT_STATUS.CONFIRMED_N_CANCELLED,
  CONSULT_STATUS.TO_BE_STARTED,
  CONSULT_STATUS.REJECTED,
];

export const CONSULT_TRANSLATIONS = Object.freeze({
  pending: "Borrador",
  requested: "Solicitada",
  accepted: "Aceptada",
  signed: "Firmada",
  paid: "Activa",
  done: "Realizada",
  cancelled: "Cancelada",
});

export const getConsultationStatusFromStatus = (status) => {
  switch (status) {
    case CONSULT_STATUS.PENDING:
    case CONSULT_STATUS.REQUESTED:
    case CONSULT_STATUS.ACCEPTED:
    case CONSULT_STATUS.SIGNED:
      return CONSULT_STATUS.IN_PROGRESS;
    case CONSULT_STATUS.PAID:
    case CONSULT_STATUS.DONE:
      return CONSULT_STATUS.CONFIRMED;
    default:
      return CONSULT_STATUS.CANCELED;
  }
};

export const isBookingAtLeastAccepted = (status) => {
  switch (status) {
    case CONSULT_STATUS.PENDING:
    case CONSULT_STATUS.REQUESTED:
    case CONSULT_STATUS.ACCEPTED:
      return false;
    case CONSULT_STATUS.SIGNED:
    case CONSULT_STATUS.PAID:
    case CONSULT_STATUS.DONE:
      return true;
    default:
      return false;
  }
};

export const isBookingConfirmedOrDone = (confirmedAt) => !!confirmedAt;

export const isBookingFinalizable = (status) => {
  switch (status) {
    case CONSULT_STATUS.PENDING:
    case CONSULT_STATUS.REQUESTED:
    case CONSULT_STATUS.ACCEPTED:
    case CONSULT_STATUS.SIGNED:
    case CONSULT_STATUS.PAID:
      return true;
    case CONSULT_STATUS.CANCELED:
    case CONSULT_STATUS.DONE:
      return false;
    default:
      return false;
  }
};

export const hasBookingInvoices = (status) =>
  [
    CONSULT_STATUS.ACCEPTED,
    CONSULT_STATUS.CANCELED,
    CONSULT_STATUS.SIGNED,
    CONSULT_STATUS.PAID,
    CONSULT_STATUS.DONE,
  ].includes(status);

const rootReducer = combineReducers({
  user: userReducer,
  consultation: consultationReducer,
  firstContact: firstContactReducer,
  consults: consultsReducer,
  listing: spaceReducer,
  spacesOwner: spacesOwnerReducer,
  spaceCard: spaceCardReducer,
  dateSelect: dateSelectReducer,
  consultsOwner: consultsOwnerReducer,
  specialOffer: specialOfferReducer,
  spaceList: spaceListReducer,
  misc: miscReducer,
  asset: assetReducer,
  region: regionReducer,
  adspaces: adspacesReducer,
  companies: companiesReducer,
  imports: importsReducer,
  googleMaps: googleMapsReducer,
  landlords: landlordsReducer,
  users: usersReducer,
  invoicingReport: invoicingReportReducer,
  tenantBookingInvoices: tenantBookingInvoicesReducer,
  performanceAnalyzer: performanceAnalyzerReducer,
  invoiceGenerate: invoiceGenerateReducer,
  pendingTransactions: pendingTransactionsReducer,
});

export default rootReducer;
