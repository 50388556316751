// Action types
export const SEND_FIRST_CONTACT = "SEND_FIRST_CONTACT";
export const SEND_FIRST_CONTACT_SUCCESS = "SEND_FIRST_CONTACT_SUCCESS";
export const SEND_FIRST_CONTACT_ERROR = "SEND_FIRST_CONTACT_ERROR";

// Action creator
export const sendFirstContact = () => ({
  type: SEND_FIRST_CONTACT,
});

export const sendFirstContactSuccess = (data) => ({
  type: SEND_FIRST_CONTACT_SUCCESS,
  payload: data,
});

export const sendFirstContactError = (error) => ({
  type: SEND_FIRST_CONTACT_ERROR,
  payload: {
    error,
  },
});

// Meet our first thunk action creator!
// Though its insides are different, you would use it just like any other action creator:
// store.dispatch(fetchPosts('reactjs'))

export function sendFirstContactThunk({ message, space, start, end, isLongTerm, paymentPlan }) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return function (dispatch, getState, { api }) {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.

    dispatch(sendFirstContact());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.
    const userId = getState().user.data.id;
    const { isAdmin } = getState().user.data;
    const postFirstContactEndpoint = isAdmin ? api.adminPostFirstContact : api.postFirstContact;

    return postFirstContactEndpoint({ space, message, start, end, isLongTerm, paymentPlan, userId })
      .then((res) =>
        res.status === 200
          ? dispatch(sendFirstContactSuccess(res.data?.payload.id))
          : dispatch(sendFirstContactError(res)),
      )
      .catch((err) => {
        dispatch(sendFirstContactError(err));
      });
  };
}

export function sendFirstContactWithoutSpaceThunk({ message, assetId, start, end, long_term }) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.

  return function (dispatch, getState, { api }) {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.

    dispatch(sendFirstContact());

    // The function called by the thunk middleware can return a value,
    // that is passed on as the return value of the dispatch method.

    // In this case, we return a promise to wait for.
    // This is not required by thunk middleware, but it is convenient for us.
    const userId = getState().user.data.id;
    const adSpaces = getState()
      .adspaces.adSpacesConsultation.map((ad) => ad.id)
      .join(",");
    const { isAdmin } = getState().user.data;
    const postFirstContactWithoutSpaceEndpoint = isAdmin
      ? api.adminPostFirstContactWithoutSpace
      : api.postFirstContactWithoutSpace;

    return postFirstContactWithoutSpaceEndpoint({
      assetId,
      adSpaces,
      message,
      start,
      end,
      long_term,
      userId,
    })
      .then((res) =>
        res.status === 200
          ? dispatch(sendFirstContactSuccess(res.data?.payload.id))
          : dispatch(sendFirstContactError(res)),
      )
      .catch((err) => {
        dispatch(sendFirstContactError(err));
      });
  };
}
