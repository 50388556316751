import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function LocalPopupIcon({
  fill = "#E5E5E5",
  width = "22px",
  height = "22px",
  ...rest
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 164 164"
      fill={fill}
      {...rest}
    >
      <path
        d="M159.6 0.200012H4.4C2 0.200012 0 2.20001 0 4.60001V20.1V20.3V47.8C0 50.2 2 52.2 4.4 52.2H7.6V159.4C7.6 161.8 9.6 163.8 12 163.8H28.8H74.3H152C154.4 163.8 156.4 161.8 156.4 159.4V52.2H159.6C162 52.2 164 50.2 164 47.8V20.3V20.1V4.60001C164 2.20001 162 0.200012 159.6 0.200012ZM155.2 9.00002V15.7H8.8V9.00002H155.2ZM153.2 43.4C152.8 43.3 152.4 43.2 152 43.2H142.8V24.7H155.1V43.4H153.2ZM8.8 43.4V24.7H21.1V43.2H12C11.6 43.2 11.2 43.3 10.8 43.4H8.8ZM75.1 24.7H88.9V43.2H75.1V24.7ZM66.3 43.2H52.5V24.7H66.3V43.2ZM97.7 24.7H111.5V43.2H97.7V24.7ZM43.7 24.7V43.2H30V24.7H43.7ZM120.3 43.2V24.7H134V43.2H120.3ZM33.2 155V71.1H69.9V155H33.2ZM147.6 155H78.6V66.7C78.6 64.3 76.6 62.3 74.2 62.3H28.7C26.3 62.3 24.3 64.3 24.3 66.7V155H16.4V52.2H147.6V155Z"
        fill={fill}
      />
      <path
        d="M88.5 114.4H135.2C137.6 114.4 139.6 112.4 139.6 110V66.8C139.6 64.4 137.6 62.4 135.2 62.4H88.5C86.1 62.4 84.1 64.4 84.1 66.8V110C84.1 112.5 86.1 114.4 88.5 114.4ZM92.9 71.2H130.8V105.6H92.9V71.2Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
