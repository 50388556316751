const INITIAL_TEMPLATE_FIELDS = {
  adminTemplateFields: [],
  adminCategories: [],
  customerTemplateFields: [],
  customerCategories: [],
};

export default (contract = {}) => {
  const templateFields = contract?.fields ? (
    Object.keys(contract.fields).reduce((acc, curr) => {
      const field = contract.fields[curr];
      switch(field.permission) {
        case "admin":
        case "other":
          return {
            ...acc,
            adminTemplateFields: [
              ...acc.adminTemplateFields,
              field
            ],
            adminCategories: acc.adminCategories.some(c => c === field.category)
              ? acc.adminCategories
              : [ ...acc.adminCategories, field.category],
          }
        case "customer":
          return {
            ...acc,
            customerTemplateFields: [
              ...acc.customerTemplateFields,
              field
            ],
            customerCategories: acc.customerCategories.some(c => c === field.category)
              ? acc.customerCategories
              : [ ...acc.customerCategories, field.category],
          }
        default:
          return acc;
      }
    }, INITIAL_TEMPLATE_FIELDS)
  ) : ({
    ...INITIAL_TEMPLATE_FIELDS
  });

  return ({
    ...contract,
    ...templateFields,
  })
}
