import { adapterFilterInvoice, getPaginationData } from "../../utils";
import { adapterInvoiceFlag } from "../../utils/adapter-invoice";
import { getYear } from "../../utils/dateUtils";

export const GET_BOOKING_INVOICING_REPORT = "GET_BOOKING_INVOICING_REPORT";
export const GET_BOOKING_INVOICING_REPORT_SUCCESS = "GET_BOOKING_INVOICING_REPORT_SUCCESS";
export const GET_BOOKING_INVOICING_REPORT_ERROR = "GET_BOOKING_INVOICING_REPORT_ERROR";

export const GET_BOOKING_INVOICING_REPORT_FILTERS = "GET_BOOKING_INVOICING_REPORT_FILTERS";
export const GET_BOOKING_INVOICING_REPORT_FILTERS_SUCCESS = "GET_BOOKING_INVOICING_REPORT_FILTERS_SUCCESS";
export const GET_BOOKING_INVOICING_REPORT_FILTERS_ERROR = "GET_BOOKING_INVOICING_REPORT_FILTERS_ERROR";

export const GET_INVOICING_REPORT = "GET_INVOICING_REPORT";
export const GET_INVOICING_REPORT_SUCCESS = "GET_INVOICING_REPORT_SUCCESS";
export const GET_INVOICING_REPORT_ERROR = "GET_INVOICING_REPORT_ERROR";

export const SET_INVOICING_REPORT_FILTERS = "SET_INVOICING_REPORT_FILTERS";

export const GET_INVOICING_REPORT_FILTERS = "GET_INVOICING_REPORT_FILTERS";
export const GET_INVOICING_REPORT_FILTERS_SUCCESS = "GET_INVOICING_REPORT_FILTERS_SUCCESS";
export const GET_INVOICING_REPORT_FILTERS_ERROR = "GET_INVOICING_REPORT_FILTERS_ERROR";

export const SET_BOOKING_INVOICING_REPORT_FILTERS = "SET_BOOKING_INVOICING_REPORT_FILTERS";

export const getBookingInvoicingReport = (page) => ({
  type: GET_BOOKING_INVOICING_REPORT,
  payload: {
    page,
  },
});

export const getBookingInvoicingReportSuccess = ({ data, pagination }) => ({
  type: GET_BOOKING_INVOICING_REPORT_SUCCESS,
  payload: {
    data,
    pagination,
  },
});

export const getBookingInvoicingReportError = (index, error) => ({
  type: GET_BOOKING_INVOICING_REPORT_ERROR,
  payload: {
    index,
    error,
  },
});

export const getBookingInvoicingReportFilters = () => ({
  type: GET_BOOKING_INVOICING_REPORT_FILTERS,
});

export const getBookingInvoicingReportFiltersSuccess = (data) => ({
  type: GET_BOOKING_INVOICING_REPORT_FILTERS_SUCCESS,
  payload: {
    data,
  },
});

export const getBookingInvoicingReportFiltersError = (error) => ({
  type: GET_BOOKING_INVOICING_REPORT_FILTERS_ERROR,
  payload: {
    error,
  },
});

export const getInvoicingReport = (page) => ({
  type: GET_INVOICING_REPORT,
  payload: {
    page,
  },
});

export const getInvoicingReportSuccess = (chart, invoices, topSpaces, balance) => ({
  type: GET_INVOICING_REPORT_SUCCESS,
  payload: {
    chart,
    invoices,
    topSpaces,
    balance,
  },
});

export const getInvoicingReportError = (index, error) => ({
  type: GET_INVOICING_REPORT_ERROR,
  payload: {
    index,
    error,
  },
});

export const setInvoicingReportFilters = (filter) => ({
  type: SET_INVOICING_REPORT_FILTERS,
  payload: filter,
});

export const getInvoicingReportFilters = () => ({
  type: GET_INVOICING_REPORT_FILTERS,
});

export const getInvoicingReportFiltersSuccess = (data) => ({
  type: GET_INVOICING_REPORT_FILTERS_SUCCESS,
  payload: {
    data: {
      ...data,
      year: getYear(new Date()),
    },
  },
});

export const getInvoicingReportFiltersError = (error) => ({
  type: GET_INVOICING_REPORT_FILTERS_ERROR,
  payload: {
    error,
  },
});

export const setBookingInvoicingReportFilters = (filter) => ({
  type: SET_BOOKING_INVOICING_REPORT_FILTERS,
  payload: filter,
});

export function getInvoicingReportThunk({ page, sort, filters }) {
  return function (dispatch, getState, { api }) {
    dispatch(getInvoicingReport(page));
    const { featureFlags } = getState().misc;
    const getInvoices = featureFlags["flag.dev.invoice_transactions"]
      ? api.getInvoicesByUserAndCreditNotesReport
      : api.getInvoicesAndCreditNotesReport;

    return getInvoices({ page, sort, filters })
      .then((response) => {
        const formatInvoices = featureFlags["flag.dev.invoice_transactions"]
          ? {
              ...response.data?.payload?.invoices,
              data: adapterInvoiceFlag(response.data?.payload?.invoices.data),
              balance: response.data?.payload.balance,
            }
          : response.data?.payload?.currentAccount;

        dispatch(
          getInvoicingReportSuccess(
            response.data.chart,
            {
              ...formatInvoices,
              pagination: getPaginationData(formatInvoices),
            },
            response.data.topSpaces,
            formatInvoices.balance,
          ),
        );
      })
      .catch((error) => {
        dispatch(getInvoicingReportError(page, error));
      });
  };
}

export function getBookingInvoicingReportThunk({ page, sort, filters }) {
  return function (dispatch, getState, { api }) {
    dispatch(getBookingInvoicingReport(page));
    const { featureFlags } = getState().misc;
    const getInvoices = featureFlags["flag.dev.invoice_transactions"]
      ? api.getBookingInvoicesByYearAndCreditNotesReport
      : api.getBookingInvoicesAndCreditNotesReport;

    return getInvoices({ page, sort, filters })
      .then((response) => {
        dispatch(
          getBookingInvoicingReportSuccess({
            data: response?.data?.payload.data,
            pagination: getPaginationData(response?.data?.payload),
          }),
        );
      })
      .catch((error) => {
        dispatch(getInvoicingReportError(page, error));
      });
  };
}

export function getInvoicingReportFiltersThunk() {
  return function (dispatch, getState, { api }) {
    dispatch(getInvoicingReportFilters());
    const { featureFlags } = getState().misc;
    const getInvoices = featureFlags["flag.dev.invoice_transactions"]
      ? api.getInvoicesByUserAndCreditNotesReportFilters
      : api.getInvoicesAndCreditNotesReportFilters;

    return getInvoices()
      .then((response) => {
        const adapter = featureFlags["flag.dev.invoice_transactions"]
          ? adapterFilterInvoice(response.data?.payload)
          : response.data?.payload;
        dispatch(getInvoicingReportFiltersSuccess(adapter));
      })
      .catch((error) => {
        dispatch(getInvoicingReportFiltersError(error));
      });
  };
}

export function getBookingInvoicingReportFilterThunk() {
  return function (dispatch, getState, { api }) {
    dispatch(getBookingInvoicingReportFilters());
    const { featureFlags } = getState().misc;
    const getFilter = featureFlags["flag.dev.invoice_transactions"]
      ? api.getBookingInvoicesByYearAndCreditNotesReportFilters
      : api.getBookingInvoicesAndCreditNotesReportFilters;

    return getFilter().then((response) => {
      if (response.status === 200) {
        const adapter = featureFlags["flag.dev.invoice_transactions"]
          ? adapterFilterInvoice(response.data?.payload, true)
          : response.data?.payload;
        dispatch(getBookingInvoicingReportFiltersSuccess(adapter));
      } else {
        dispatch(getBookingInvoicingReportFiltersError());
      }
    });
  };
}
