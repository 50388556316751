import numbro from "numbro";
import { t } from "@lingui/macro";
import { formatCurrencyForTenant } from "./currencyUtils";
import PromotionIcon from "../icons/local-popup-icon";
import KioskIcon from "../icons/kiosk-icon";
import AdvertisingIcon from "../icons/ad-icon";
import EventsIcon from "../icons/events-icon";
import MallRetailIcon from "../icons/mall-retail";
import CustomerAcquisitionIcon from "../icons/customer-acquisition";
import AmbientMediaIcon from "../icons/ambient-media";
import VendingIcon from "../icons/vending";

/**
 * Spots feature utils
 */

export const getIconFromSpotSlug = (slug) => {
  switch (slug) {
    case "advertising":
      return AdvertisingIcon;
    case "retail":
      return PromotionIcon;
    case "events":
      return EventsIcon;
    case "mall-retail":
      return MallRetailIcon;
    case "customer-acquisition":
      return CustomerAcquisitionIcon;
    case "ambient-media":
      return AmbientMediaIcon;
    case "brand":
      return AdvertisingIcon;
    case "vending":
      return VendingIcon;
    case "promotion":
    default:
      return KioskIcon;
  }
};

export const getSpacePriceConfigFromSpot = (spot) => {
  switch (spot) {
    case "promotion":
      return "daily";
    case "retail":
      return "monthly";
    default:
      return "daily";
  }
};

export const getSpacePriceFromConfig = (space, priceConfig) => {
  switch (priceConfig) {
    case "daily":
      return space.display_price;
    case "monthly":
      return space.full_month_price;
    default:
      return space.display_price;
  }
};

export const getTenantPriceBySpot = (spot, monthPrice, dayPrice) => {
  switch (spot) {
    case "retail":
      return `${formatCurrencyForTenant(monthPrice)}/${t`mes`}`;
    case "promotion":
      return `${formatCurrencyForTenant(dayPrice)}/${t`día`}`;
    default:
      return `${formatCurrencyForTenant(dayPrice)}/${t`día`}`;
  }
};

export const getPriceBySpot = (spot, monthPrice, dayPrice) => {
  switch (spot) {
    case "retail":
      return `${numbro.formatCurrency(monthPrice)}/${t`mes`}`;
    case "promotion":
      return `${numbro.formatCurrency(dayPrice)}/${t`día`}`;
    default:
      return `${numbro.formatCurrency(dayPrice)}/${t`día`}`;
  }
};
