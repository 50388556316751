/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import {
  GET_PENDING_TRANSACTIONS,
  GET_PENDING_TRANSACTIONS_SUCCESS,
  GET_PENDING_TRANSACTIONS_ERROR,
} from "../actions/pending-transactions-actions";

const INITIAL_DATA = {
  pagination: {
    page: 1,
    totalPages: 1,
  },
  transactions: [],
  status: "initial",
};

const pendingTransactionsReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_PENDING_TRANSACTIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        status: "loading",
      };
    case GET_PENDING_TRANSACTIONS_SUCCESS:
      const { data } = action.payload;

      return {
        ...state,
        transactions: data.data || [],
        pagination: {
          page: data.current_page,
          totalPages: data.last_page,
        },
        status: "success",
      };
    case GET_PENDING_TRANSACTIONS_ERROR:
      return {
        ...state,
        status: "error",
      };
    default:
      return {
        ...state,
      };
  }
};

export default pendingTransactionsReducer;
