import axios from "axios";
import config from "../config.js";
import { formatDateUtc } from "../utils/dateUtils.js";

const BASE_URL_API = config.API_URL + "/api/v1";

export const getAssetSpaceOccupation = async (asset, filters) => {
  const start = formatDateUtc(filters.start, "YYYY-MM-DD");
  const end = formatDateUtc(filters.end, "YYYY-MM-DD");

  return axios.get(`${BASE_URL_API}/assets/${asset}/occupation?start=${start}&end=${end}`);
};

export const getAssetAdSpacesOccupation = async (asset, filters) => {
  const start = formatDateUtc(filters.start, "YYYY-MM-DD");
  const end = formatDateUtc(filters.end, "YYYY-MM-DD");

  return axios.get(`${BASE_URL_API}/assets/${asset}/adspaces-occupation?start=${start}&end=${end}`);
};
