export const GET_REGION = "GET_REGION";
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS";
export const GET_REGION_ERROR = "GET_REGION_ERROR";
export const RESET_REGION = "RESET_REGION";

export const getRegion = () => ({
  type: GET_REGION,
});

export const getRegionSuccess = (region) => ({
  type: GET_REGION_SUCCESS,
  payload: region,
});

export const getRegionError = (error) => ({
  type: GET_REGION_ERROR,
  payload: error,
});

export const resetRegion = () => ({
  type: RESET_REGION,
});

export function getRegionThunk(id) {
  return function (dispatch, _, { api }) {
    dispatch(getRegion());
    return api.getRegion(id).then((res) => {
      if (res.status === 200) {
        dispatch(getRegionSuccess(res?.data?.payload));
      } else {
        dispatch(dispatch(getRegionError()));
      }
    });
  };
}
