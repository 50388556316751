import {
  GET_SPACES_LIST,
  GET_SPACES_LIST_SUCCESS,
  GET_SPACES_LIST_ERROR,
  GET_SPACES_NEXT_LIST,
  GET_SPACES_NEXT_LIST_SUCCESS,
  GET_SPACES_NEXT_LIST_ERROR,
  ADD_SPACES_FILTER,
  ADD_SPACES_FILTER_SUCCESS,
  SET_SPACE_HOVER,
} from "../actions/space-list-actions";
import { transformAsset, transformSpace } from "./space-reducer";

export const MAX_PRICE = 7000;
export const MAX_SIZE = 4000;

const INITIAL_STATE = {
  asset: {
    config: {},
  },
  spaces: [],
  spaceHover: -1,
  types: [], // these went to misc along with "uses"
  services: [], // these and "features" should go to misc and change in the container list spaces.
  filters: {
    start: null,
    end: null,
    priceStart: 0,
    priceEnd: MAX_PRICE,
    priceMin: 0,
    priceMax: MAX_PRICE,
    sizeStart: 0,
    sizeEnd: MAX_SIZE,
    sizeMin: 0,
    sizeMax: MAX_SIZE,
    types: [],
    features: [],
    uses: [],
  },
  filtersUrl: "",
  status: "initial",
  statusType: "inital",
  statusServices: "initial",
  statusFilter: "initial",
  currentPage: 1,
  lastPage: 1,
  perPage: 12,
  error: "",
};

const SpaceListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SPACES_LIST:
      return {
        ...state,
        status: "loading",
        asset: { ...action.payload },
        spaces: [],
      };
    case GET_SPACES_LIST_SUCCESS:
      return {
        ...state,
        status: "success",
        asset: transformAsset(action.payload.asset),
        spaces: action.payload.data.map((s) => transformSpace(s)),
        currentPage: action.payload.current_page,
        lastPage: action.payload.last_page,
        perPage: action.payload.per_page,
      };
    case GET_SPACES_LIST_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    case GET_SPACES_NEXT_LIST:
      return {
        ...state,
        status: "loading",
      };
    case GET_SPACES_NEXT_LIST_SUCCESS:
      return {
        ...state,
        status: "success",
        spaces: [
          ...state.spaces,
          ...action.payload.data.map((s) => transformSpace(s)),
        ],
        currentPage: action.payload.current_page,
        lastPage: action.payload.last_page,
      };
    case GET_SPACES_NEXT_LIST_ERROR:
      return {
        ...state,
        status: "error",
      };
    case ADD_SPACES_FILTER:
      return {
        ...state,
        statusFilter: "loading",
      };
    case ADD_SPACES_FILTER_SUCCESS: {
      const asset = action.payload.asset ? transformAsset(action.payload.asset) : state.asset;
      const filters = {
        ...state.filters,
        ...action.payload,
        asset: asset?.id,
      };
      const filtersUrl = Object.keys(filters)
        .map((f) =>
          Array.isArray(filters[f])
            ? `${f}=${filters[f].map((f) => f?.name).join(",")}`
            : `${f}=${filters[f]}`
        )
        .join("&");

      return {
        ...state,
        filters,
        filtersUrl,
        asset,
        statusFilter: "success",
      };
    }
    case SET_SPACE_HOVER:
      return {
        ...state,
        spaceHover: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default SpaceListReducer;
