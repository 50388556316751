export const GET_ASSET = "GET_ASSET";
export const GET_ASSET_SUCCESS = "GET_ADSPACES_SUCCESS";
export const GET_ASSET_ERROR = "GET_ADSPACES_ERROR";

export const RESET_ASSET = "RESET_ASSET";

export const getAsset = () => ({
  type: GET_ASSET,
});

export const getAssetSuccess = (payload) => ({
  type: GET_ASSET_SUCCESS,
  payload,
});

export const getAssetError = (error) => ({
  type: GET_ASSET_ERROR,
  payload: error,
});

export const resetAsset = () => ({
  type: RESET_ASSET,
});

export function getAssetThunk(id) {
  return function (dispatch, _, { api }) {
    dispatch(getAsset());
    return api.getAsset(id).then((res) => {
      if (res.status === 200) {
        dispatch(getAssetSuccess(res?.data?.payload));
      } else {
        dispatch(dispatch(getAssetError()));
      }
    });
  };
}
