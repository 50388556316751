export const GET_PENDING_TRANSACTIONS = "GET_PENDING_TRANSACTIONS";
export const GET_PENDING_TRANSACTIONS_SUCCESS = "GET_PENDING_TRANSACTIONS_SUCCESS";
export const GET_PENDING_TRANSACTIONS_ERROR = "GET_PENDING_TRANSACTIONS_ERROR";

export const getPendingTransactions = (page) => ({
  type: GET_PENDING_TRANSACTIONS,
  payload: {
    page,
  },
});

export const getPendingTransactionsSuccess = (data) => ({
  type: GET_PENDING_TRANSACTIONS_SUCCESS,
  payload: {
    data,
  },
});

export const getPendingTransactionsError = (error) => ({
  type: GET_PENDING_TRANSACTIONS_ERROR,
  payload: error,
});

export function getPendingTransactionsThunk({ start, end, sort, page }) {
  return function (dispatch, _, { api }) {
    dispatch(getPendingTransactions(page));
    api.getPendingTransactions({ start, end, sort, page }).then((res) => {
      if (res.status === 200) {
        dispatch(getPendingTransactionsSuccess(res.data?.payload));
      } else {
        dispatch(getPendingTransactionsError(res.data?.message));
      }
    });
  };
}
