import React from "react";

export default function EventIcon({ fill = "black", ...rest }) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.614 44.8391V26.9623C40.185 26.6642 40.6301 26.348 41.0702 26.0149L40.3701 25.1041C40.1342 25.2829 39.9945 25.4555 39.6138 25.6236V23.1393H38.4717V26.289C37.52 26.8254 36.3779 27.2935 35.0454 27.6858V4.10448H14.8685V27.6262C13.7264 27.2217 12.5843 26.74 11.4422 26.1885V23.1391H10.3001V25.4981C10.1098 25.3695 9.94418 25.2387 9.76659 25.1041L9.06668 26.0149C9.45156 26.306 9.91944 26.5837 10.3001 26.8485V44.8391H7.63525V45.9812H14.9115H35.0885H42.469V44.8391H39.614ZM14.8687 44.8391H11.4424V27.4717C12.5845 27.9869 13.7266 28.4383 14.8687 28.8182V44.8391ZM33.9035 44.8391H16.0108V29.1405C18.866 29.8499 21.8269 30.2361 25.0523 30.2361C28.2032 30.2361 31.2387 29.8672 33.9035 29.1892V44.8391ZM33.9035 28.0149C31.429 28.7135 28.2262 29.0942 25.0523 29.0942C21.8019 29.0942 18.866 28.6945 16.0108 27.9637V5.24658H33.9035V28.0149ZM38.4719 44.8391H35.0456V28.8751C36.3781 28.506 37.5202 28.068 38.4719 27.5657V44.8391Z"
        fill={fill}
      />
    </svg>
  );
}
