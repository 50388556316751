import {
  GET_ASSET,
  GET_ASSET_SUCCESS,
  GET_ASSET_ERROR,
  RESET_ASSET,
} from "../actions/asset-actions";

const ASSET_INITIAL_DATA = {
  status: "initial",
  data: {
    description: "",
    type: "",
    address: "",
    tax_id: "",
    latitude: -1,
    longitude: 1,
    city_id: 0,
    agent_id: 0,
    legal_name: "",
    account_id: 0,
    domiciliation_min_days: 0,
    home_order: false,
    styles: {},
    invoices_data: {},
  },
};

const AssetReducer = (state = ASSET_INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_ASSET:
      return {
        ...state,
        status: "loading",
      };
    case GET_ASSET_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload,
          // just to set a real init object instead of null
          invoices_data: action.payload.invoices_data || {},
        },
        status: "success",
      };
    case GET_ASSET_ERROR:
      return {
        ...state,
        status: "error",
      };
    case RESET_ASSET:
      return {
        ...ASSET_INITIAL_DATA,
      };
    default:
      return {
        ...state,
      };
  }
};

export default AssetReducer;
