import axios from "axios";
import config from "../config";
import { getUrlPreSignedAvatar, addUrlSignedAvatar, getUrlPreSignedInvoice, addUrlSignedInvoice } from "./v2/client";
import {
  adminGetUrlPreSignedAvatar,
  adminAddUrlSignedAvatar,
  adminGetUrlPreSignedInvoice,
  adminAddUrlSignedInvoice,
} from "./v2/admin";

const BASE_URL_API = `${config.API_URL}/api/v1`;

/**
 * For update a file you can use updateFile method.
 * We have 3 step when you need upload files:
 * 1- getUrlPreSigned method: get URL from api pre signed by S3
 * 2- uploadFileToS3 method: update the file to S3
 * 3- addUrlSigned method: add the URL in our data base
 */

const getUrlPreSigned = async (model, id, filename, fileExt) => {
  return axios.post(`${BASE_URL_API}/attachments/url/${model}/${id}`, {
    file_name: filename,
    file_ext: fileExt,
  });
};

const uploadFileToS3 = async (dataPreSigned, file) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", dataPreSigned.url, true);

    xhr.setRequestHeader("Content-Type", file.type);
    xhr.setRequestHeader("Accept", "application/json");

    xhr.send(file);
    xhr.onload = function () {
      this.status === 200 ? resolve() : reject(this.responseText);
    };
  });
};

const addUrlSigned = async (model, id, url) => {
  return axios.post(`${BASE_URL_API}/attachments/add/${model}/${id}`, {
    url,
  });
};

const isValidResponse = (status) => {
  if (status !== 200) {
    throw new Error("We have an error with upload a file");
  }
};

const avatarStepUpdateFile = async (isAdmin, id, filename, fileExt, file) => {
  const resPreSigned = isAdmin
    ? await adminGetUrlPreSignedAvatar(id, filename, fileExt)
    : await getUrlPreSignedAvatar(id, filename, fileExt);
  isValidResponse(resPreSigned.status);

  await uploadFileToS3(resPreSigned.data?.payload, file);

  const resAddURL = isAdmin
    ? await adminAddUrlSignedAvatar(id, resPreSigned.data?.payload?.url)
    : await addUrlSignedAvatar(id, resPreSigned.data?.payload?.url);
  isValidResponse(resAddURL.status);

  return resAddURL;
};

const avatarStepUpdateInvoice = async (isAdmin, id, filename, fileExt, file) => {
  const resPreSigned = isAdmin
    ? await adminGetUrlPreSignedInvoice(id, filename, fileExt)
    : await getUrlPreSignedInvoice(id, filename, fileExt);
  isValidResponse(resPreSigned.status);

  await uploadFileToS3(resPreSigned.data?.payload, file);

  const resAddURL = isAdmin
    ? await adminAddUrlSignedInvoice(id, resPreSigned.data?.payload?.url)
    : await addUrlSignedInvoice(id, resPreSigned.data?.payload?.url);
  isValidResponse(resAddURL.status);

  return resAddURL;
};

export const updateFile = async (isAdmin, model, id, filename, fileExt, file, isAvatar, isInvoice = false) => {
  try {
    if (isAvatar) {
      const res = avatarStepUpdateFile(isAdmin, id, filename, fileExt, file);
      return res;
    }

    if (isInvoice) {
      const res = avatarStepUpdateInvoice(isAdmin, id, filename, fileExt, file);
      return res;
    }

    const resPreSigned = await getUrlPreSigned(model, id, filename, fileExt);
    isValidResponse(resPreSigned.status);

    await uploadFileToS3(resPreSigned.data?.payload, file);

    const resAddURL = await addUrlSigned(model, id, resPreSigned.data?.payload?.url);
    isValidResponse(resAddURL.status);

    return resAddURL;
  } catch (error) {
    console.log("errorrr", error);
    return error;
  }
};

export const getAttachmentByClient = async (entity, id) => {
  return axios.get(`${BASE_URL_API}/attachments/list/${entity}/${id}`);
};

export const deleteAttachment = async (id) => {
  return axios.delete(`${BASE_URL_API}/attachments/${id}`, {});
};

export const updateExpirationDateAttachment = async (id, date) => {
  return axios.patch(`${BASE_URL_API}/attachments/${id}`, { expire_at: date });
};
