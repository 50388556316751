import { getPaginationData } from "../../utils";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";

export const getUsersList = (page) => ({
  type: GET_USERS_LIST,
  payload: {
    page,
  },
});

export const getUsersListSuccess = (payload) => ({
  type: GET_USERS_LIST_SUCCESS,
  payload: {
    data: payload.data,
    pagination: payload.pagination,
  },
});

export const getUsersListError = (index, error) => ({
  type: GET_USERS_LIST_ERROR,
  payload: {
    index,
    error,
  },
});

export const getUsersListThunk = ({ pageSize, page, sort } = {}) => {
  return function (dispatch, _, { api }) {
    dispatch(getUsersList(page));
    return api
      .getUsersList({ pageSize, page, sort })
      .then((response) => {
        dispatch(
          getUsersListSuccess({
            ...response.data.payload,
            pagination: getPaginationData(response.data.payload),
          }),
        );
      })
      .catch((error) => {
        dispatch(getUsersListError(page, error));
      });
  };
};
