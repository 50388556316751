import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function KioskIcon({
  fill = "#E5E5E5",
  width = "22px",
  height = "22px",
  ...rest
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 148 172"
      fill={fill}
      {...rest}
    >
      <path
        d="M143.1 108.1H137.4V55.2H128.7V108.1H19.7V55.2H11V108.1H4.9C2.5 108.1 0.5 110 0.5 112.5V166.8C0.5 169.2 2.4 171.2 4.9 171.2H143.2C145.6 171.2 147.6 169.3 147.6 166.8V112.5C147.5 110.1 145.6 108.1 143.1 108.1ZM138.8 116.8V128.6H9.2V116.8H138.8ZM9.2 162.4V137.3H138.8V162.4H9.2Z"
        fill={fill}
      />
      <path
        d="M4.9 55.1H143.2C144.6 55.1 145.9 54.4 146.8 53.3C147.6 52.1 147.8 50.7 147.3 49.3L137.7 22.5V5.19998C137.7 2.79998 135.8 0.799988 133.3 0.799988H14.7C12.3 0.799988 10.3 2.69998 10.3 5.19998V22.8L0.699995 49.2C0.199995 50.5 0.399995 52 1.19999 53.2C2.09999 54.4 3.4 55.1 4.9 55.1ZM56.5 28.1L55.6 46.4H36.5L38.9 28.1H56.5ZM65.2 28.1H82.8L83.7 46.4H64.2L65.2 28.1ZM91.5 28.1H109.1L111.5 46.4H92.4L91.5 28.1ZM17.7 28.1H30.1L27.7 46.4H11.1L17.7 28.1ZM120.3 46.4L117.9 28.1H130.4L137 46.4H120.3ZM19.1 9.59998H129V19.2H19.1V9.59998Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
