import {
  GET_CONSULTS_OWNER,
  GET_CONSULTS_OWNER_SUCCESS,
  GET_CONSULTS_OWNER_ERROR,
  SET_CONSULTS_OWNER_STATE,
  SET_CONSULTS_SEARCH_WORDS,
  SET_CONSULTS_FILTERS,
  GET_CONSULTS_FILTERS,
  GET_CONSULTS_FILTERS_SUCCESS,
  GET_CONSULTS_FILTERS_ERROR,
} from "../actions/consults-owner-actions";
import { GET_BOOKING_SUCCESS } from "../actions/consultation-actions";
import { getNow } from "../../utils/dateUtils";

const storedAssetsFilter =
  typeof localStorage !== "undefined" ? JSON.parse(localStorage.getItem("consultsAssets")) || [] : [];

const INITIAL_DATA = {
  items: [],
  total: 25,
  pagination: {
    page: 1,
    totalPages: 1,
  },
  lastPage: 0,
  status: "initial",
  bookingState: null,
  searchWords: "",
  filters: { assets: storedAssetsFilter },
  filtersOptions: {
    assets: [],
  },
};

const consultsReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_CONSULTS_OWNER:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        status: "loading",
      };
    case GET_CONSULTS_OWNER_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        pagination: action.payload.pagination,
        bookingState: action.payload.state,
        status: "success",
      };
    case GET_CONSULTS_OWNER_ERROR:
      return {
        ...state,
        status: "error",
      };
    case SET_CONSULTS_OWNER_STATE:
      return {
        ...state,
        bookingState: action.payload,
      };
    case SET_CONSULTS_SEARCH_WORDS:
      return {
        ...state,
        searchWords: action.payload,
      };
    case GET_BOOKING_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        items: state.items.map((c) =>
          c.id === data.id
            ? {
                ...c,
                user_last_seen: getNow().format(),
              }
            : c,
        ),
      };
    }
    case SET_CONSULTS_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case GET_CONSULTS_FILTERS:
      return {
        ...state,
        statusFilters: "loading",
      };
    case GET_CONSULTS_FILTERS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        statusFilters: "success",
        filtersOptions: {
          assets: data.assets,
        },
      };
    }
    case GET_CONSULTS_FILTERS_ERROR:
      return {
        ...state,
        statusFilters: "error",
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default consultsReducer;
