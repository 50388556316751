import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VendingIcon({ fill = "#333333", ...rest }) {
  return (
    <SvgIcon width="190" height="190" viewBox="0 0 190 190" fill="none" {...rest}>
      <path d="M37.9 133.1H112.8C114.8 133.1 116.5 131.5 116.5 129.4V27.9999C116.5 25.9999 114.9 24.2999 112.8 24.2999H37.9C35.9 24.2999 34.2 25.8999 34.2 27.9999V129.5C34.3 131.5 35.9 133.1 37.9 133.1ZM56.2 66.6999H52.2V51.8999H56.2V66.6999ZM109.2 73.9999V107.6H105.9V89.0999C105.9 87.0999 104.3 85.3999 102.2 85.3999H90.9C88.9 85.3999 87.1999 86.9999 87.1999 89.0999V107.6H84.5999V89.0999C84.5999 87.0999 83 85.3999 80.9 85.3999H69.6999C67.6999 85.3999 66 86.9999 66 89.0999V107.6H63.4V89.0999C63.4 87.0999 61.8 85.3999 59.7 85.3999H48.5999C46.5999 85.3999 44.9 86.9999 44.9 89.0999V107.6H41.5999V73.9999H109.2ZM77.4 66.6999H73.4V51.8999H77.4V66.6999ZM98.5999 66.6999H94.5999V51.8999H98.5999V66.6999ZM98.5999 107.6H94.5999V92.6999H98.5999V107.6ZM77.4 107.6H73.4V92.6999H77.4V107.6ZM56.2 107.6H52.2V92.6999H56.2V107.6ZM41.5999 125.8V114.9H109.2V125.8H41.5999ZM109.2 31.5999V66.6999H105.9V48.1999C105.9 46.1999 104.3 44.4999 102.2 44.4999H90.9C88.9 44.4999 87.1999 46.0999 87.1999 48.1999V66.6999H84.5999V48.1999C84.5999 46.1999 83 44.4999 80.9 44.4999H69.6999C67.6999 44.4999 66 46.0999 66 48.1999V66.6999H63.4V48.1999C63.4 46.1999 61.8 44.4999 59.7 44.4999H48.5999C46.5999 44.4999 44.9 46.0999 44.9 48.1999V66.6999H41.5999V31.5999H109.2Z" fill={fill} />
      <path d="M166.2 10.2H23.8C21.8 10.2 20.1 11.8 20.1 13.9V176.2C20.1 178.2 21.7 179.9 23.8 179.9H166.1C168.1 179.9 169.8 178.3 169.8 176.2V13.9C169.8 11.8 168.2 10.2 166.2 10.2ZM162.5 172.5H27.5V17.5H162.5V172.5Z" fill={fill} />
      <path d="M126.9 102.9H151.9C153.9 102.9 155.6 101.3 155.6 99.1999V27.9999C155.6 25.9999 154 24.2999 151.9 24.2999H126.9C124.9 24.2999 123.2 25.8999 123.2 27.9999V99.2999C123.2 101.3 124.9 102.9 126.9 102.9ZM130.5 31.5999H148.2V95.5999H130.5V31.5999Z" fill={fill} />
      <path d="M37.9 165.7H112.8C114.8 165.7 116.5 164.1 116.5 162V143.5C116.5 141.5 114.9 139.8 112.8 139.8H37.9C35.9 139.8 34.2 141.4 34.2 143.5V162C34.3 164.1 35.9 165.7 37.9 165.7ZM41.5999 147.2H109.2V158.4H41.5999V147.2Z" fill={fill} />
      <path d="M151.6 111.4H127.7C125.7 111.4 124 113 124 115.1C124 117.2 125.6 118.8 127.7 118.8H151.6C153.6 118.8 155.3 117.2 155.3 115.1C155.3 113 153.6 111.4 151.6 111.4Z" fill={fill} />
      <path d="M151.6 125.8H127.7C125.7 125.8 124 127.4 124 129.5C124 131.6 125.6 133.2 127.7 133.2H151.6C153.6 133.2 155.3 131.6 155.3 129.5C155.3 127.4 153.6 125.8 151.6 125.8Z" fill={fill} />
    </SvgIcon>
  );
}
