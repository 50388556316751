import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function OutsideIcon({ fill = "#E5E5E5", ...rest }) {
  return (
    <SvgIcon
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      {...rest}
    >
      <path
        d="M144.183 151.354V47.5986H148.967L156.028 20.3946L157.85 15.5442L153.522 14.068C150.561 13.0136 147.372 12.381 144.183 12.1701C144.639 11.5374 144.867 10.6939 144.867 9.85034C144.867 7.10885 142.589 5 139.628 5C136.667 5 134.389 7.10885 134.389 9.85034C134.389 10.6939 134.617 11.3265 135.072 11.9592C131.883 12.1701 128.694 12.8027 125.733 13.6463L121.633 14.9116L122.772 18.7075L130.289 47.1769H135.072V151.143H111.611V129.844H123.228V121.408H16.1722V129.844H27.7889V151.565H0V160H164V151.565H144.183V151.354ZM146.689 21.0272L141.906 39.1633H137.35L132.567 21.0272C137.122 19.9728 142.133 20.1837 146.689 21.0272ZM36.9 129.844H102.5V151.565H36.9V129.844Z"
        fill={fill}
      />
      <path
        d="M31.1986 120H40.264V114.053H99.1892V120H108.255V114.053H118V105.558H108.028V100.035H118V91.5398H108.028V86.0177H118V77.5221H108.028V72H98.9626V77.3097H40.0374V72H30.972V77.3097H21V85.8053H30.972V91.3274H21V99.823H30.972V105.345H21V113.841H30.972V120H31.1986ZM40.264 85.8053H99.1892V91.3274H40.264V85.8053ZM40.264 100.035H99.1892V105.558H40.264V100.035Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
