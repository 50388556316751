import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";
import theme from "../../theme";

//Browser's Icons
import Chrome from "../../img/browsers/chrome.png";
import Firefox from "../../img/browsers/firefox.png";
import Opera from "../../img/browsers/opera.png";
import Safari from "../../img/browsers/safari.png";
import Edge from "../../img/browsers/edge.png";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      padding: theme.spacing(2),
    },
  },
  wrapped: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "1100px",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  browserContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    width: "900px",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  browser: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
  logo: {
    width: "360px",
    height: "152px",
  },
}));

const BROWSERS_ICONS = [
  {
    name: "Chrome",
    icon: Chrome,
    link: "https://www.google.com/chrome/",
  },
  {
    name: "Firefox",
    icon: Firefox,
    link: "https://www.mozilla.org/",
  },
  {
    name: "Opera",
    icon: Opera,
    link: "https://www.opera.com/",
  },
  {
    name: "Safari",
    icon: Safari,
    link: "https://www.apple.com/safari/",
  },
  {
    name: "Edge",
    icon: Edge,
    link: "https://www.microsoft.com/en-us/edge",
  },
];

function IncompatibleBrowser() {
  const classes = useStyles(theme);

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapped}>
        <Box mb={4}>
          <Box className={classes.logo}>
            <Image
              disableSpinner
              color={theme.palette.darkGray}
              src={"/images/crenex-logo-light.jpg"}
              className={classes.logo}
              style={{
                height: "100%",
                padding: "0",
                width: "100%",
                backgroundColor: "white",
              }}
              imageStyle={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        </Box>
        <Typography
          variant="h1"
          style={{ textAlign: "center", color: theme.palette.black }}
        >
          <Trans>
            USTED ESTÁ UTILIZANDO UN NAVEGADOR WEB NO SOPORTADO POR ESTE SITIO
          </Trans>
        </Typography>
        <Box mt={4}>
          <Typography
            variant="body1"
            style={{ textAlign: "center", color: theme.palette.black }}
          >
            <Trans>
              Utilice o actualice uno de los siguientes navegadores para
              aprovechar al máximo este sitio web
            </Trans>
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h1" color="primary" style={{ fontSize: "32px" }}>
            <Trans>¡Gracias!</Trans>
          </Typography>
        </Box>
        <Box className={classes.browserContainer}>
          {BROWSERS_ICONS.map((b) => (
            <Box className={classes.browser}>
              <Link href={b.link}>
                <Image
                  disableSpinner
                  color={theme.palette.darkGray}
                  src={b.icon}
                  style={{
                    height: "64px",
                    padding: "0",
                    width: "64px",
                    backgroundColor: "white",
                  }}
                  imageStyle={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <Box mt={2}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.palette.black,
                      textAlign: "center",
                      textDecoration: "underline",
                    }}
                  >
                    {b.name}
                  </Typography>
                </Box>
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default IncompatibleBrowser;
