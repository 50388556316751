/* eslint-disable no-case-declarations */
import { GET_USERS_LIST, GET_USERS_LIST_SUCCESS, GET_USERS_LIST_ERROR } from "../actions/users-actions";

const INITIAL_DATA = {
  pagination: {
    page: 1,
    totalPages: 1,
  },
  users: [],
  status: "initial",
};

const usersReducer = (state = INITIAL_DATA, action) => {
  switch (action.type) {
    case GET_USERS_LIST:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload.page,
        },
        status: "loading",
      };
    case GET_USERS_LIST_SUCCESS:
      const { data, pagination } = action.payload;
      return {
        ...state,
        users: data || [],
        status: "success",
        pagination,
      };
    case GET_USERS_LIST_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default usersReducer;
