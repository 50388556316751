import { getPaginationData } from "../../utils";

export const GET_LANDLORDS = "GET_LANDLORDS";
export const GET_LANDLORDS_SUCCESS = "GET_LANDLORDS_SUCCESS";
export const GET_LANDLORDS_ERROR = "GET_LANDLORDS_ERROR";

export const SET_LANDLORDS_FILTERS = "SET_LANDLORDS_FILTERS";

export const getLandlords = (page) => ({
  type: GET_LANDLORDS,
  payload: {
    page,
  },
});

export const getLandlordsSuccess = (payload) => ({
  type: GET_LANDLORDS_SUCCESS,
  payload: {
    data: payload.data,
    pagination: payload.pagination,
  },
});

export const getLandlordsError = (index, error) => ({
  type: GET_LANDLORDS_ERROR,
  payload: {
    index,
    error,
  },
});

export const setLandlordsFilters = (filter) => ({
  type: SET_LANDLORDS_FILTERS,
  payload: filter,
});

export function getLandlordsThunk({ page, numberPerPage, sort, filters }) {
  return function (dispatch, _, { api }) {
    dispatch(getLandlords(page));
    return api
      .getLandlords({ page, numberPerPage, sort, filters })
      .then((response) => {
        dispatch(getLandlordsSuccess({
          ...response.data.payload,
          pagination: getPaginationData(response.data.payload),
        }));
      })
      .catch((error) => {
        dispatch(getLandlordsError(page, error));
      });
  };
}
