import { getPaginationData } from "../../utils";

export const GET_IMPORTS = "GET_IMPORTS";
export const GET_IMPORTS_SUCCESS = "GET_IMPORTS_SUCCESS";
export const GET_IMPORTS_ERROR = "GET_IMPORTS_ERROR";

export const getImports = (page) => ({
  type: GET_IMPORTS,
  payload: {
    page,
  },
});

export const getImportsSuccess = ({ pagination, data }) => ({
  type: GET_IMPORTS_SUCCESS,
  payload: {
    data,
    pagination,
  },
});

export const getImportsError = (index, error) => ({
  type: GET_IMPORTS_ERROR,
  payload: {
    index,
    error,
  },
});

export function getImportsThunk({ page, sort, search }) {
  return function (dispatch, _, { api }) {
    dispatch(getImports(page));
    return api
      .getImportsList({ page, sort, search })
      .then((response) => {
        dispatch(
          getImportsSuccess({
            data: response.data.payload.data,
            pagination: getPaginationData(response.data.payload),
          }),
        );
      })
      .catch((error) => {
        dispatch(getImportsError(page, error));
      });
  };
}
