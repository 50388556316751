export const GET_SPACE = "GET_SPACE";
export const GET_SPACE_SUCCESS = "GET_SPACE_SUCCESS";
export const GET_SPACE_ERROR = "GET_SPACE_ERROR";
export const GET_SPOTS_CATEGORIES = "GET_SPOTS_CATEGORIES";
export const GET_SPOTS_CATEGORIES_SUCCESS = "GET_SPOTS_CATEGORIES_SUCCESS";
export const GET_SPOTS_CATEGORIES_ERROR = "GET_SPOTS_CATEGORIES_ERROR";
export const POST_SPACE_CATEGORIES = "POST_SPACE_CATEGORIES";
export const POST_SPACE_CATEGORIES_SUCCESS = "POST_SPACE_CATEGORIES_SUCCESS";
export const POST_SPACE_CATEGORIES_ERROR = "POST_SPACE_CATEGORIES_ERROR";
export const POST_SPACE = "POST_SPACE";
export const POST_SPACE_SUCCESS = "POST_SPACE_SUCCESS";
export const POST_SPACE_ERROR = "POST_SPACE_ERROR";
export const PUT_SPACE = "PUT_SPACE";
export const PUT_SPACE_SUCCESS = "PUT_SPACE_SUCCESS";
export const PUT_SPACE_ERROR = "PUT_SPACE_ERROR";
export const RESET_SPACE = "RESET_SPACE";
export const PUBLISH_SPACE = "PUBLISH_SPACE";
export const PUBLISH_SPACE_SUCCESS = "PUBLISH_SPACE_SUCCESS";
export const PUBLISH_SPACE_ERROR = "PUBLISH_SPACE_ERROR";
export const SET_SPACE_ATTACHMENTS = "SET_SPACE_ATTACHMENTS";
export const SET_SPACE_ATTACHMENTS_SUCCESS = "SET_SPACE_ATTACHMENTS_SUCCESS";
export const SET_SPACE_ATTACHMENTS_ERROR = "SET_SPACE_ATTACHMENTS_ERROR";
export const GET_SPACE_UNAVAILABLE_DATE_RANGES = "GET_SPACE_UNAVAILABLE_DATE_RANGES_DATES";
export const GET_SPACE_UNAVAILABLE_DATE_RANGES_ERROR = "GET_SPACE_UNAVAILABLE_DATE_RANGES_ERROR";
export const GET_SPACE_UNAVAILABLE_DATE_RANGES_SUCCESS = "GET_SPACE_UNAVAILABLE_DATE_RANGES_SUCCESS";
export const UPDATE_DATA_NEW_SPACE_SUCCESS = "UPDATE_DATA_NEW_SPACE_SUCCESS";

export const getSpace = (id) => ({
  type: GET_SPACE,
  payload: { id },
});

export const getSpaceSuccess = (id, owner, data) => ({
  type: GET_SPACE_SUCCESS,
  payload: { id, owner, data },
});

export const getSpaceError = (error) => ({
  type: GET_SPACE_ERROR,
  payload: { error },
});

export const getSpotsCategories = () => ({
  type: GET_SPOTS_CATEGORIES,
});

export const getSpotsCategoriesSuccess = (data) => ({
  type: GET_SPOTS_CATEGORIES_SUCCESS,
  payload: data,
});

export const getSpotsCategoriesError = (error) => ({
  type: GET_SPOTS_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const postSpaceCategories = () => ({
  type: POST_SPACE_CATEGORIES,
});

export const postSpaceCategoriesSuccess = (data) => ({
  type: POST_SPACE_CATEGORIES_SUCCESS,
  payload: data,
});

export const postSpaceCategoriesError = (error) => ({
  type: POST_SPACE_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const postSpace = () => ({
  type: POST_SPACE,
});

export const postSpaceSuccess = (data) => ({
  type: POST_SPACE_SUCCESS,
  payload: { data },
});

export const postSpaceError = (error) => ({
  type: POST_SPACE_ERROR,
  payload: { error },
});

export const putSpace = () => ({
  type: PUT_SPACE,
});

export const putSpaceSuccess = (data) => ({
  type: PUT_SPACE_SUCCESS,
  payload: { data },
});

export const putSpaceError = (error) => ({
  type: PUT_SPACE_ERROR,
  payload: { error },
});

export const publishListing = () => ({
  type: PUBLISH_SPACE,
});

export const publishListingSuccess = (listingId, searchable) => ({
  type: PUBLISH_SPACE_SUCCESS,
  payload: { listingId, searchable },
});

export const publishListingError = (error) => ({
  type: PUBLISH_SPACE_ERROR,
  payload: { error },
});

export const setSpaceAttachments = (id, file) => ({
  type: SET_SPACE_ATTACHMENTS,
});

export const setSpaceAttachmentsSuccess = (id, attachments) => ({
  type: SET_SPACE_ATTACHMENTS_SUCCESS,
  payload: { id, attachments },
});

export const setSpaceAttachmentsError = (error) => ({
  type: SET_SPACE_ATTACHMENTS_ERROR,
  payload: { error },
});

export const getSpaceUnavailableDateRanges = (id) => ({
  type: GET_SPACE_UNAVAILABLE_DATE_RANGES,
  payload: { id },
});

export const getSpaceUnavailableDateRangesSuccess = (data) => ({
  type: GET_SPACE_UNAVAILABLE_DATE_RANGES_SUCCESS,
  payload: { data },
});

export const getSpaceUnavailableDateRangesError = (error) => ({
  type: GET_SPACE_UNAVAILABLE_DATE_RANGES_ERROR,
  payload: { error },
});

export const resetSpace = () => ({
  type: RESET_SPACE,
});

export const updateDataNewSpaceSuccess = (data) => ({
  type: UPDATE_DATA_NEW_SPACE_SUCCESS,
  payload: data,
});

export function getSpaceThunk(id, userId, isAdmin) {
  return function(dispatch, getState, { api }) {
    dispatch(getSpace(id));
    return (isAdmin ? api.getAdminSpace(id) : api.getSpace(id))
      .then((response) => {
        dispatch(getSpaceSuccess(id, userId, response.data))
      })
      .catch((error) => {
        console.log(error);
        dispatch(getSpaceError(error));
      });
  };
}

export function getSpotsCategoriesThunk(assetId) {
  return function (dispatch, getState, { api }) {
    dispatch(getSpotsCategories());
    return api
      .getSpotsCategoriesByAsset(assetId)
      .then((res) =>
        dispatch(
          getSpotsCategoriesSuccess({
            ...res.data.payload,
          }),
        ),
      )
      .catch((err) => dispatch(getSpotsCategoriesError(err)));
  };
}

export function postSpaceCategoriesThunk(spaceId, tags) {
  return function (dispatch, getState, { api }) {
    dispatch(postSpaceCategories());
    return api
      .setSpaceCategories(spaceId, tags)
      .then((res) =>
        dispatch(
          postSpaceCategoriesSuccess({
            ...res.data.payload,
          }),
        ),
      )
      .catch((err) => dispatch(postSpaceCategoriesError(err)));
  };
}

export function postSpaceThunk(data, userId) {
  return function(dispatch, getState, { api }) {
    dispatch(postSpace());
    return api
      .postSpace(
        {
          asset_id: data.asset.id,
          owner_id: userId,
          space_type_id: data.type.id,
          space_sub_type_id: data.subtype.id,
          ...data,
        },
        userId,
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(postSpaceSuccess(res.data?.payload));
        } else {
          dispatch(postSpaceError(res.response?.data?.message));
        }
      });
  };
}

export function putSpaceThunk(id, data) {
  return function(dispatch, getState, { api }) {
    dispatch(putSpace());
    const d = {
      ...data,
      asset_id: data.asset.id,
      // type view
      space_type_id: data.type.id,
      space_sub_type_id: data.subtype.id,
      capacity: parseInt(data.capacity),
      size: parseInt(data.size),
      // characteristics view
      // services: data.services.map((s) => s.id).join(","),
      features: data.features.map((f) => f.id).join(","),
      // update this field to BAC-639
      uses: data.uses.map((s) => s.id).join(","),
      // images view
      attachments: [...data.images, ...data.plans],
      contract_model_id: data.contractModelId,
      contract_model_attachment: data.contractModelAttachment,
      dimensions: data.dimensions === '' ? ' ' : data.dimensions,
    };

    return api
      .putSpace(id, d)
      .then(() => dispatch(putSpaceSuccess(data)))
      .catch((error) => {
        dispatch(putSpaceError(error));
      });
  };
}

export function publishSpaceThunk({ listingId, hide }) {
  return function(dispatch, getState, { api }) {
    if (!listingId) {
      return;
    }
    const userId = getState().user.data.id;
    dispatch(publishListing());
    const publishCall = hide ? api.hideListing(listingId, userId) : api.publishListing(listingId, userId);
    return publishCall
      .then(() => dispatch(publishListingSuccess(listingId, !hide)))
      .catch((error) => {
        dispatch(publishListingError(error));
      });
  };
}

export function setSpaceAttachmentsThunk(id, attachments) {
  return function(dispatch, getState, { api }) {
    dispatch(setSpaceAttachments(id));
    return api
      .setSpaceAttachments(id, attachments)
      .then(() => dispatch(setSpaceAttachmentsSuccess(id, attachments)))
      .catch((error) => {
        dispatch(setSpaceAttachmentsError(error));
      });
  };
}

export function getSpaceUnavailableDateRangesThunk(id, userId) {
  return function(dispatch, getState, { api }) {
    dispatch(getSpaceUnavailableDateRanges(id));
    return api
      .getSpaceUnavailableDateRanges(id)
      .then((response) => dispatch(getSpaceUnavailableDateRangesSuccess(response.data.payload)))
      .catch((error) => {
        dispatch(getSpaceUnavailableDateRangesError(error));
      });
  };
}

// ver orden
