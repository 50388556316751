import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function OthersIcon({
  fill = "#E5E5E5",
  width = 26,
  height = 26,
  ...rest
}) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 142 42"
      fill={fill}
      {...rest}
    >
      <path
        d="M20.4394 0.560608C9.25152 0.560608 0 9.81212 0 21C0 32.1879 9.25152 41.4394 20.4394 41.4394C31.6273 41.4394 40.8788 32.1879 40.8788 21C40.8788 9.81212 31.8424 0.560608 20.4394 0.560608ZM20.4394 32.8333C13.9849 32.8333 8.60606 27.4545 8.60606 21C8.60606 14.5455 13.9849 9.16667 20.4394 9.16667C26.8939 9.16667 32.2727 14.5455 32.2727 21C32.2727 27.4545 27.1091 32.8333 20.4394 32.8333Z"
        fill={fill}
      />
      <path
        d="M70.9999 0.560608C59.8121 0.560608 50.5605 9.81212 50.5605 21C50.5605 32.1879 59.8121 41.4394 70.9999 41.4394C82.1878 41.4394 91.4393 32.1879 91.4393 21C91.4393 9.81212 82.1878 0.560608 70.9999 0.560608ZM70.9999 32.8333C64.5454 32.8333 59.1666 27.4545 59.1666 21C59.1666 14.5455 64.5454 9.16667 70.9999 9.16667C77.4545 9.16667 82.8333 14.5455 82.8333 21C82.8333 27.4545 77.4545 32.8333 70.9999 32.8333Z"
        fill={fill}
      />
      <path
        d="M121.561 0.560608C110.373 0.560608 101.121 9.81212 101.121 21C101.121 32.1879 110.373 41.4394 121.561 41.4394C132.748 41.4394 142 32.1879 142 21C142 9.81212 132.748 0.560608 121.561 0.560608ZM121.561 32.8333C115.106 32.8333 109.727 27.4545 109.727 21C109.727 14.5455 115.106 9.16667 121.561 9.16667C128.015 9.16667 133.394 14.5455 133.394 21C133.394 27.4545 128.015 32.8333 121.561 32.8333Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
