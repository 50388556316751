import {
  SEND_FIRST_CONTACT,
  SEND_FIRST_CONTACT_SUCCESS,
  SEND_FIRST_CONTACT_ERROR,
} from "../actions/first-contact-actions";
import { GET_CONSULTS } from "../actions/consults-actions";

const INITIAL_STATE = {
  bookingId: null,
  status: "initial",
  startDate: new Date(),
  endDate: new Date(),
  error: "",
};

const listingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_FIRST_CONTACT:
      return {
        ...state,
        status: "loading",
      };
    case SEND_FIRST_CONTACT_SUCCESS:
      return {
        ...state,
        status: "success",
        bookingId: action.payload,
      };
    case SEND_FIRST_CONTACT_ERROR:
      return {
        ...state,
        status: "error",
        error: action.payload.error?.response?.data?.message,
      };
    case GET_CONSULTS: {
      return {
        ...state,
        status: "initial",
        error: "",
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default listingReducer;
